define("hospodari/components/city/city-modal", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "hospodari/config/environment"], function (_exports, _toConsumableArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cities = (0, _toConsumableArray2.default)(_environment.default.address.cities);

  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    // Attributes
    cities: cities,
    city: cities[0],
    // Actions
    actions: {
      setCity: function setCity(city) {
        // console.log(city);
        var me = Ember.get(this, 'me');
        var onChange = Ember.get(this, 'onChange');

        if (me.id) {
          var user = Ember.get(this, 'me.user');
          Ember.set(me, 'data.city', city.name);
          Ember.set(user, 'city', city.name);
          user.save();
        } else {
          Ember.set(this, 'city', city.name);
        }

        onChange(city);
        Ember.set(this, 'show', false);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/business/business-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RsaXk2bJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card business-list-item\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-3\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"logo_url\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[11,\"src\",[24,[\"item\",\"logo_url\"]]],[10,\"alt\",\"Логотип\"],[10,\"class\",\"img-fluid\"],[10,\"width\",\"80\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"ho ho-portfolio\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"h4\",true],[10,\"class\",\"title text-uppercase\"],[8],[0,\"\\n    \"],[4,\"if\",[[24,[\"item\",\"verified\"]]],null,{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-check-circle-o text-info\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"]],\"parameters\":[]},null],[1,[24,[\"item\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"business/business-rate\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"item\",\"is_active\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"text-success\"],[8],[0,\"активний\"],[9],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"text-muted\"],[8],[0,\"до \"],[1,[28,\"moment-format\",[[24,[\"item\",\"activated\"]],\"DD.MM.YYYY\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"text-danger\"],[8],[7,\"small\",true],[8],[0,\"неактивний\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-list-item.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/business/business-transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K5K5t7He",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card business-invoices p-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"business-aside col-12 col-md-3\"],[8],[0,\"\\n      \"],[1,[28,\"business/business-navigation\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"font-weight-bold\"],[8],[0,\"\\n        Баланс: ₴\"],[1,[28,\"number-format\",[[24,[\"item\",\"balance\"]]],[[\"format\"],[\"0.0\"]]],false],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"title\",\"route\",\"model\"],[\"text-success\",\"Поповнити баланс\",\"my.business.buy\",[24,[\"item\",\"id\"]]]],{\"statements\":[[0,\"          \"],[7,\"i\",true],[10,\"class\",\"fa fa-credit-card\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Поповнити баланс\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"transaction/transactions-list\",null,[[\"items\"],[[24,[\"items\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        У вас ще немає транзакцій.\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-transactions.hbs"
    }
  });

  _exports.default = _default;
});
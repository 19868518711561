define("hospodari/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "45c6M9qO",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"error/error-404\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});
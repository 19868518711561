define("hospodari/components/order/order-form", ["exports", "moment", "hospodari/utils/get-error-message"], function (_exports, _moment, _getErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Flags
    isNew: null,
    // Computed
    applied: Ember.computed('item.apply_deadline', function () {
      var momentDate = Ember.get(this, 'item.apply_deadline') || (0, _moment.default)();
      return momentDate.toDate();
    }),
    finaled: Ember.computed('item.final_deadline', function () {
      var momentDate = Ember.get(this, 'item.final_deadline') || (0, _moment.default)();
      return momentDate.toDate();
    }),
    // Actions
    actions: {
      updateDate: function updateDate(a, b) {
        Ember.set(this, a, (0, _moment.default)(b));
      },
      objectChanged: function objectChanged(property) {
        Ember.set(this, 'item.address', Ember.get(property, 'address'));
        Ember.set(this, 'item.address_area_level_1', Ember.get(property, 'address_area_level_1'));
        Ember.set(this, 'item.address_area_level_2', Ember.get(property, 'address_area_level_2'));
        Ember.set(this, 'item.address_postal_code', Ember.get(property, 'address_postal_code'));
        Ember.set(this, 'item.city', Ember.get(property, 'city'));
        Ember.set(this, 'item.address_type', Ember.get(property, 'address_type'));
        Ember.set(this, 'item.address_name', Ember.get(property, 'address_name'));
        Ember.set(this, 'item.address_building', Ember.get(property, 'address_building'));
        Ember.set(this, 'item.address_apartment', Ember.get(property, 'address_apartment'));
      },
      save: function save(item) {
        var _this = this;

        var isNew = Ember.get(this, 'item.isNew');
        return item.validate().then(function () {
          if (item.get('isValid')) {
            return item.save().then(function (item) {
              var propertyId = parseInt(Ember.get(item, 'property.id'));
              var orderId = parseInt(item.id);

              if (isNew) {
                Ember.get(_this, 'router').transitionTo('signup-order-complete');
              } else {
                Ember.get(_this, 'router').transitionTo('my.buildings.order', propertyId, orderId);
              }
            }).catch(function (data) {
              var error = JSON.parse(data.errors[0].detail).error.message;
              Ember.set(_this, 'error', (0, _getErrorMessage.default)(error));
            });
          }
        });
      },
      delete: function _delete() {
        var _this2 = this;

        if (confirm('Ви дійсно бажаєте видалити цю угоду?')) {
          var item = Ember.get(this, 'item');
          item.destroyRecord().then(function (item) {
            Ember.get(_this2, 'router').transitionTo('my.orders.index');
          }).catch(function (data) {
            var error = JSON.parse(data.errors[0].detail).error.message;
            console.log(error);
            Ember.set(_this2, 'error', (0, _getErrorMessage.default)(error));
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/input-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showApartment: true,
    actions: {
      handleCityDetailsChange: function handleCityDetailsChange(details) {
        this.get("item").setProperties(details ? Ember.getProperties(details, "address_area_level_1", "address_area_level_2", "address_postal_code") : {
          address_area_level_1: null,
          address_area_level_2: null,
          address_postal_code: null
        });
      }
    }
  });

  _exports.default = _default;
});
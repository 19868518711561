define("hospodari/templates/signup-building-commercial-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JFnVRELW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"building/signup-building-complete\",null,[[\"title\"],[\"Ваш об'єкт додано!\"]],{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"Ви успішно додали комерційну нерухомість. Тепер додайте угоду.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/signup-building-commercial-complete.hbs"
    }
  });

  _exports.default = _default;
});
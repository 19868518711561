define("hospodari/components/business/business-members", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    role: null,
    roles: _environment.default.business.roles,
    error: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var roles = Ember.get(this, 'roles');
      Ember.set(this, 'role', roles[0]);
    },
    // Actions
    actions: {
      onUserChange: function onUserChange(item) {
        Ember.set(this, 'error', null);
      },
      onUserSelect: function onUserSelect(item) {
        Ember.set(this, 'user', item);
      },
      addUser: function addUser() {
        var _this = this;

        var store = Ember.get(this, 'store');
        var user = Ember.get(this, 'user');

        if (!user) {
          Ember.set(this, 'error', 'Користувача не обрано. Будь ласка, знайдіть необхідного користувача через пошук і оберіть потрібного.');
          return;
        }

        var entityId = Ember.get(this, 'item.id');
        var userId = Ember.get(this, 'user.id');
        var role = Ember.get(this, 'role.value');
        console.log(entityId, userId, role);
        store.findRecord('entity', entityId).then(function (entity) {
          store.findRecord('user', userId).then(function (user) {
            var item = store.createRecord('entityuser', {
              entity: entity,
              user: user,
              role: role
            });
            item.save().then(function () {
              Ember.get(_this, 'onUserCreated')();
            }).catch(function (data) {
              console.log(data);
              var error = data.errors[0];
              var message = JSON.parse(error.detail).error.message;

              if (message === "Entity already has role for this User") {
                Ember.set(_this, 'error', 'Цей користувач вже має роль.');
              } else if (message === "This user do not have right to assign roles") {
                Ember.set(_this, 'error', 'Ви не можете призначати ролі.');
              } else {
                Ember.set(_this, 'error', 'Помилка. Будь ласка, зверніться до адміністрації.');
              }
            });
          });
        });
      },
      setRole: function setRole(role) {
        Ember.set(this, 'role', role);
      }
    }
  });

  _exports.default = _default;
});
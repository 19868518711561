define("hospodari/components/input-address-type", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "hospodari/config/environment"], function (_exports, _toConsumableArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var types = (0, _toConsumableArray2.default)(_environment.default.address.types);

  var _default = Ember.Component.extend({
    tagName: '',
    types: types,
    value: null,
    actions: {
      select: function select(type) {
        this.trigger('onChange', type.short);
      }
    }
  });

  _exports.default = _default;
});
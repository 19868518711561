define("hospodari/routes/buildings/osbb-rating", ["exports", "fetch", "hospodari/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Рейтинг ОСББ',
    // Model
    model: function model() {
      var _config$api = _environment.default.api,
          host = _config$api.host,
          path = _config$api.path;
      return (0, _fetch.default)("".concat(host).concat(path, "/analytics/condominiums-rating")).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      });
    }
  });

  _exports.default = _default;
});
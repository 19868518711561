define("hospodari/routes/my/business/offers", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Пропозиції',
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var entityId = parseInt(params.id);
      var projectId = parseInt(_environment.default.api.project);
      var productId = parseInt(_environment.default.api.product);
      return store.findRecord('entity', entityId).then(function (entity) {
        return Ember.RSVP.hash({
          item: entity,
          customer: store.findRecord('neuron', entityId),
          project: store.findRecord('project', projectId),
          product: store.findRecord('product', productId),
          offers: store.query('offer', {
            'filter[project]': projectId,
            'filter[product]': productId,
            'filter[entity]': entityId
          })
        });
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
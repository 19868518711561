define("hospodari/validations/order-validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstname: [(0, _validators.validatePresence)(true)],
    lastname: [(0, _validators.validatePresence)(true)],
    phone: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^\+380\d{9}$/,
      message: 'Невірний формат. Приклад: +380991112233'
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    property: [(0, _validators.validatePresence)(true)],
    city: [(0, _validators.validatePresence)(true)],
    address_type: [(0, _validators.validatePresence)(true)],
    address_name: [(0, _validators.validatePresence)(true)],
    address_building: [(0, _validators.validatePresence)(true)],
    service: [(0, _validators.validatePresence)(true)],
    title: [(0, _validators.validatePresence)(true)],
    // summary: [validatePresence(true)],
    // apply_deadline: [validatePresence(true)],
    // final_deadline: [validatePresence(true)],
    amount: [// validatePresence(true),
    (0, _validators.validateNumber)({
      allowBlank: true,
      positive: true,
      lt: 10e9
    })]
  };
  _exports.default = _default;
});
define("hospodari/routes/my/buildings/invoices", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        item: store.findRecord('property', params.id),
        items: store.query('invoice', {
          'filter[customer]': params.id
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/business/business-services-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    items: [{
      title: 'Діагностика ліфтів',
      icon: "ho-elevator"
    }, {
      title: 'Енергоаудит',
      icon: "ho-light-bulb"
    }, {
      title: 'Електроаудит',
      icon: "ho-energy-class"
    }]
  });

  _exports.default = _default;
});
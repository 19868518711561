define("hospodari/templates/buildings/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "11xnEdcu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"common/common-header\",null,[[\"search\",\"yield\",\"menu\"],[false,false,false]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row mt-4 mb-4\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-8 offset-md-2\"],[8],[0,\"\\n        \"],[1,[22,\"outlet\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/buildings/item.hbs"
    }
  });

  _exports.default = _default;
});
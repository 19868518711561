define("hospodari/templates/components/review/review-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NMylajmI",
    "block": "{\"symbols\":[\"review\"],\"statements\":[[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"pb-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mb-1 lead\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-comment-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[23,1,[\"author\",\"fullname\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex align-items-center mb-1\"],[8],[0,\"\\n      \"],[5,\"review/rate\",[[12,\"class\",\"pr-3\"]],[[\"@rating\"],[[23,1,[\"rating\"]]]]],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"text-muted small\"],[8],[0,\"\\n        \"],[1,[28,\"moment-format\",[[23,1,[\"created\"]],\"DD-MM-YYYY HH:mm\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[23,1,[\"comment\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/review/review-list.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/building/building-form", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-changeset-validations/validators"], function (_exports, _objectSpread2, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var titleCommonValidation = (0, _validators.validateFormat)({
    regex: /^[^"']+$/,
    message: 'Вкажіть назву без лапок',
    allowBlank: true
  });
  var propertyValidations = {
    title: [titleCommonValidation],
    city: [(0, _validators.validatePresence)(true)],
    address_type: [(0, _validators.validatePresence)(true)],
    address_name: [(0, _validators.validatePresence)(true)],
    address_building: [(0, _validators.validatePresence)(true)],
    phone: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^\+380\d{9}$/,
      message: 'Невірний формат. Приклад: +380991112233'
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    area_total: [(0, _validators.validatePresence)(true)]
  };
  var apartmentValidations = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, propertyValidations), {}, {
    address_apartment: [(0, _validators.validatePresence)(true)],
    flat_count: [(0, _validators.validatePresence)(true)]
  });
  var privateValidations = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, propertyValidations), {}, {
    flat_count: [(0, _validators.validatePresence)(true)]
  });
  var commercialValidations = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, propertyValidations), {}, {
    floor_count: [(0, _validators.validatePresence)(true)]
  });
  var condominiumValidations = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, propertyValidations), {}, {
    title: [(0, _validators.validatePresence)(true), titleCommonValidation],
    floor_count: [(0, _validators.validatePresence)(true)],
    flat_count: [(0, _validators.validatePresence)(true)],
    entrance_count: [(0, _validators.validatePresence)(true)]
  });

  var _default = Ember.Component.extend({
    apartmentValidations: apartmentValidations,
    privateValidations: privateValidations,
    commercialValidations: commercialValidations,
    condominiumValidations: condominiumValidations
  });

  _exports.default = _default;
});
define("hospodari/components/offer/offer-table-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Actions
    actions: {
      click: function click(offer) {
        var order = Ember.get(this, 'order');
        var orderId = parseInt(Ember.get(order, 'id'));
        var propertyId = parseInt(Ember.get(order, 'property.id'));
        var offerId = parseInt(Ember.get(offer, 'id'));
        Ember.get(this, 'router').transitionTo('my.buildings.offer', propertyId, orderId, offerId);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/service/catalog", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    category: null,
    page: 1,
    per_page: 10,
    q: '',
    customHandler: false,
    buttonLabel: 'Переглянути',
    compact: false,
    categories: null,
    // top-level services
    sort: 'order',
    sortOptions: null,
    services: null,
    initializing: false,
    loading: false,
    debouncedSearch: null,
    // default event handlers
    onChange: function onChange() {},
    updateSearch: function updateSearch(q) {
      this.set('debouncedSearch', q || null);
      this.fetchServices();
    },
    fetchCategories: function fetchCategories() {
      var _this = this;

      this.set('loadingCategories', true);
      return this.get('store').query('service', {
        'filter[parent]': 'null',
        'filter[display]': 'public',
        'filter[service_type]': 'job',
        per_page: 100,
        sort: 'title'
      }).then(function (categories) {
        return _this.set('categories', categories);
      }).finally(function () {
        return _this.set('loadingCategories', false);
      });
    },
    fetchServices: function fetchServices() {
      var _this2 = this;

      var debouncedSearch = this.debouncedSearch,
          category = this.category,
          page = this.page,
          per_page = this.per_page,
          sort = this.sort;
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        per_page: per_page,
        sort: sort,
        'filter[display]': 'public',
        'filter[service_type]': 'job'
      }, category && {
        'filter[parent]': category.id
      }), debouncedSearch && {
        q: debouncedSearch
      });
      this.set('loading', true);
      return this.store.query('service', params).then(function (services) {
        return _this2.set('services', services);
      }).finally(function () {
        return _this2.set('loading', false);
      });
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this.set('initializing', true);
      this.set('sortOptions', [{
        label: 'змовчанням',
        value: 'order'
      }, {
        label: 'найменуванням',
        value: 'title'
      }, {
        label: 'переглядами',
        value: '-views'
      }, {
        label: 'новизною',
        value: '-created'
      }]);
      this.fetchCategories();
      this.fetchServices().finally(function () {
        return _this3.set('initializing', false);
      });
    },
    actions: {
      handleCategoryChange: function handleCategoryChange(category) {
        this.setProperties({
          category: category,
          page: 1
        });
        this.fetchServices();
      },
      handlePageChange: function handlePageChange(page) {
        this.set('page', page);
        this.fetchServices();
      },
      handleSortChange: function handleSortChange(sort) {
        this.set('sort', sort);
        this.fetchServices();
      },
      handleSearch: function handleSearch(q) {
        Ember.run.debounce(this, this.updateSearch, q, 250); // make search empty on "Clear" button click

        if (!q) this.set('q', null);
      },
      handleServiceChange: function handleServiceChange(service) {
        return this.onChange(service);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/services/me", ["exports", "ember-fetch/ajax", "hospodari/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Services
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    moment: Ember.inject.service(),
    router: Ember.inject.service(),
    // Properties
    token: _environment.default.api.token,
    data: null,
    signed: null,
    user: null,
    // Variables
    id: Ember.computed.reads('data.id'),
    admin: Ember.computed.reads('data.admin'),
    email: Ember.computed.reads('data.email'),
    phone: Ember.computed.reads('data.phone'),
    firstname: Ember.computed.reads('data.firstname'),
    lastname: Ember.computed.reads('data.lastname'),
    fullname: Ember.computed.reads('data.fullname'),
    city: Ember.computed.reads('data.city'),
    locale: Ember.computed.reads('data.locale'),
    timezone: Ember.computed.reads('data.timezone'),
    // Computed Variables
    rights: Ember.computed.reads('data.rights'),
    imageStyle: Ember.computed('data.image_url', function () {
      var imageUrl = Ember.get(this, 'data.image_url');
      return Ember.String.htmlSafe("background-image: url(".concat(imageUrl, ");"));
    }),
    write: function write(token, data) {
      var _this = this;

      // Ember.Logger.log('Writing session data...');
      // Ember.Logger.log('Token: ', token);
      // Write token to Session
      Ember.set(this, 'session.token', token); // Write token and data to local storage

      Ember.set(this, 'token', token);
      Ember.set(this, 'data', data);
      Ember.set(this, 'signed', true); // Load User

      Ember.get(this, 'store').findRecord('user', data.id).then(function (user) {
        return Ember.set(_this, 'user', user);
      }); // Set moment timezone

      var timezone = Ember.get(this, 'timezone');
      var moment = Ember.get(this, 'moment'); // moment.setTimeZone(timezone);
    },
    clear: function clear() {
      // Clear token from Session
      Ember.get(this, 'session').set('token', null); // Clear token from Cookies

      Ember.get(this, 'cookies').clear('token'); // Clear data from local storage

      Ember.set(this, 'token', null);
      Ember.set(this, 'data', null);
      Ember.set(this, 'signed', false);
      Ember.set(this, 'user', null);
    },
    // Load
    load: function load(token) {
      var _this2 = this;

      var sessionToken = Ember.get(this, 'session.token');
      var cookiesToken = Ember.get(this, 'cookies').read('token');
      if (!token) token = sessionToken || cookiesToken;
      if (!token) return;
      var options = {
        headers: {
          'X-LivaRava-Token': token
        }
      };
      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var site = _environment.default.api.site;
      var time = new Date().getTime();
      var url = "".concat(host).concat(path, "/me") + "?__t=".concat(time) + "&site=".concat(site);
      var request = (0, _ajax.default)(url, options);
      request.then(function (response) {
        var data = response.data; // Write data from remote server

        _this2.write(token, data);

        return data;
      }).catch(function () {// Do something
      });
      return request;
    },
    reload: function reload() {
      var _this3 = this;

      var token = Ember.get(this, 'token');
      if (!token) return;
      var options = {
        headers: {
          'X-LivaRava-Token': token
        }
      };
      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var site = _environment.default.api.site;
      var time = new Date().getTime();
      var url = "".concat(host).concat(path, "/me") + "?__t=".concat(time) + "&site=".concat(site);
      var request = (0, _ajax.default)(url, options);
      request.then(function (response) {
        var data = response.data; // Write data from remote server

        _this3.write(token, data);

        return data;
      }).catch(function () {// Do something
      });
      return request;
    },
    // Sign Up
    signUp: function signUp(firstname, lastname, phone, email, password) {
      var _this4 = this;

      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var site = _environment.default.api.site;
      var time = new Date().getTime(); // use `encodeURIComponent` to fix possible encoding errors

      var url = "".concat(host).concat(path, "/signup/") + "?__t=".concat(encodeURIComponent(time)) + "&firstname=".concat(encodeURIComponent(firstname)) + "&lastname=".concat(encodeURIComponent(lastname)) + "&phone=".concat(encodeURIComponent(phone)) + "&email=".concat(encodeURIComponent(email)) + "&password=".concat(encodeURIComponent(password)) + "&site=".concat(encodeURIComponent(site));
      var request = (0, _ajax.default)(url);
      request.then(function (response) {
        var token = response.token;
        var data = response.data;

        _this4.write(token, data);
      }).catch(function () {// Do something
      });
      return request;
    },
    // Sign In
    signIn: function signIn(login, password) {
      var _this5 = this;

      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var site = _environment.default.api.site;
      var time = new Date().getTime();
      var url = "".concat(host).concat(path, "/signin/") + "?__t=".concat(time) + "&login=".concat(login) + "&password=".concat(password) + "&site=".concat(site);
      var request = (0, _ajax.default)(url);
      request.then(function (response) {
        var token = response.token;
        var data = response.data;

        if (response.error) {
          return;
        }

        _this5.write(token, data);
      }).catch(function () {// Do something
      });
      return request;
    },
    // Sign Out
    signOut: function signOut() {
      var _this6 = this;

      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var site = _environment.default.api.site;
      var time = new Date().getTime();
      var token = Ember.get(this, 'token');
      var url = "".concat(host).concat(path, "/signout/") + "?__t=".concat(time) + "&token=".concat(token) + "&site=".concat(site);
      var request = (0, _ajax.default)(url);
      request.then(function () {
        _this6.clear();
      }).catch(function () {// Do something
      }).finally(function () {// Do something
      });
      return request;
    },

    /**
     * Заказчик авторизуется
     * если нет объекта (только после авторизации)
     * происходит переадресация на страницу создания объекта
     * после этого - переадресация на страницу создания заказа
     * после создания заказа - переадресация на список заказов
     * если есть объект (только после авторизации)
     * если нет заказов
     * переадресация на страницу создания заказа
     * если есть заказы
     * переадресация на страницу со списком своих заказов
     */
    redirectAfterSignIn: function redirectAfterSignIn() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var store = Ember.get(this, "store");
      var router = Ember.get(this, "router"); // if has entities then type=business else type=customer

      var userId = parseInt(this.id);
      var projectId = _environment.default.api.project;
      var productId = _environment.default.api.product;
      var entityusers = store.query("entityuser", {
        "filter[user]": userId
      });
      var propertyusers = store.query("propertyuser", {
        "filter[user]": userId
      });
      var orders = store.query("order", {
        "filter[author]": userId,
        "filter[project]": projectId,
        "filter[product]": productId
      });
      return Ember.RSVP.hash({
        entityusers: entityusers,
        propertyusers: propertyusers,
        orders: orders
      }).then(function (_ref) {
        var entityusers = _ref.entityusers,
            propertyusers = _ref.propertyusers,
            orders = _ref.orders;
        var isBusiness = type == 'business' || entityusers.length > 0;
        var isCustomer = type == 'customer' || propertyusers.length > 0;

        if (isBusiness) {
          // После регистрации бизнеса - переадресация на страницу с описанием алгоритма работы платформы
          if (entityusers.length == 0) {
            return router.transitionTo("signup-business-owner-complete");
          } else {
            return router.transitionTo("my");
          }
        } else if (isCustomer) {
          if (propertyusers.length == 0) {
            // если нет объекта
            return router.transitionTo("signup-building-owner-complete");
          } else if (orders.length == 0) {
            // если нет заказов переадресация на страницу создания заказа
            return router.transitionTo("signup-order-continue");
          } else {
            // если есть заказы переадресация на страницу со списком своих заказов
            return router.transitionTo("my.orders");
          }
        } else {
          return router.transitionTo("signup-continue");
        }
      });
    }
  });

  _exports.default = _default;
});
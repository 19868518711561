define("hospodari/templates/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vFh/vddk",
    "block": "{\"symbols\":[\"floatingBlock\"],\"statements\":[[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"common/common-header\",null,[[\"search\",\"title\"],[false,\"\"]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"container body mt-5 mb-5\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-8 offset-md-2\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"model\",\"description\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-spin\"],[8],[9],[0,\"\\n          Завантажується...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"froala-content\",null,null,{\"statements\":[[0,\"            \"],[1,[24,[\"model\",\"description\"]],true],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"path\"]],\"/avariyna-plus/\"],null]],null,{\"statements\":[[0,\"            \"],[1,[28,\"pages/avariyna-turn-on\",null,[[\"class\"],[\"mb-3\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"common/common-footer\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"error/error-404\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"floating-block\",[],[[\"@buttonLabel\",\"@timeout\"],[[28,\"if\",[[24,[\"media\",\"isResponsive\"]],\"Замовити\",\"Заявка в 1 клік\"],null],\"0\"]],{\"statements\":[[0,\"\\n  \"],[5,\"order/quick-order\",[],[[\"@onClose\"],[[28,\"action\",[[23,0,[]],[23,1,[\"toggle\"]],false],null]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/page.hbs"
    }
  });

  _exports.default = _default;
});
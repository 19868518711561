define("hospodari/models/review", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    // Base
    type: 'review',
    rating: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('project'),
    product: _emberData.default.belongsTo('product'),
    order: _emberData.default.belongsTo('order'),
    entity: _emberData.default.belongsTo('entity')
  });

  _exports.default = _default;
});
define("hospodari/controllers/my/buildings/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'per_page'],
    page: 1,
    per_page: 10
  });

  _exports.default = _default;
});
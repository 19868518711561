define("hospodari/templates/buildings/item/join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L9TcunMT",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[28,\"join-to-osbb\",null,[[\"item\"],[[24,[\"model\",\"item\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/buildings/item/join.hbs"
    }
  });

  _exports.default = _default;
});
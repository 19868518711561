define("hospodari/controllers/password", ["exports", "ember-fetch/ajax", "hospodari/config/environment", "md5"], function (_exports, _ajax, _environment, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Query Params
    queryParams: ['email', 'code'],
    // Attributes
    email: null,
    code: null,
    password: null,
    confirm: null,
    error: null,
    // States
    isSending: false,
    isDisabled: Ember.computed('password', 'confirm', 'isSending', function () {
      var isSending = Ember.get(this, 'isSending');
      var password = Ember.get(this, 'password');
      var confirm = Ember.get(this, 'confirm');
      return isSending || !password || !confirm || password.length < 8 || password !== confirm;
    }),
    // Actions
    actions: {
      onSend: function onSend() {
        var _this = this;

        var me = Ember.get(this, 'me');
        var host = _environment.default.api.host;
        var path = _environment.default.api.path;
        var time = new Date().getTime();
        var email = Ember.get(this, 'email');
        var code = Ember.get(this, 'code');
        var password = (0, _md.default)(Ember.get(this, 'password'));
        var confirm = (0, _md.default)(Ember.get(this, 'confirm'));
        var site = _environment.default.api.site;
        var url = "".concat(host).concat(path, "/password-change/") + "?__t=".concat(time) + "&email=".concat(email) + "&code=".concat(code) + "&password=".concat(password) + "&confirm=".concat(confirm) + "&site=".concat(site);
        this.set('isSending', true);
        this.set('error', null);
        (0, _ajax.default)(url).then(function (response) {
          // console.log(response);
          _this.set('isSending', false);

          _this.set('error', null);

          if (response.error) {
            _this.set('error', "Error: ".concat(response.error) || 'Unexpected error. Please, report to admin@livarava.com');
          } else {
            _this.set('isSending', true);

            me.signIn(email, password).then(function () {
              _this.set('isSending', false);

              _this.send('toIndex');
            }).catch(function () {
              _this.set('error', response.error || 'Login error. Please, report to admin@livarava.com');
            });
          }
        }).catch(function () {
          // console.log(response);
          _this.set('isSending', false);

          _this.set('error', 'Unexpected error. Please, report to admin@livarava.com');
        });
      }
    }
  });

  _exports.default = _default;
});
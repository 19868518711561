define("hospodari/templates/components/building/building-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PvBODzc0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card building-list-item\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[7,\"i\",true],[11,\"class\",[29,[[24,[\"item\",\"property_icon\"]]]]],[8],[9],[9],[0,\"\\n    \"],[7,\"small\",true],[10,\"class\",\"text-small text-muted\"],[8],[1,[24,[\"item\",\"property_title\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"h4\",true],[10,\"class\",\"title text-uppercase\"],[8],[0,\"\\n    \"],[4,\"if\",[[24,[\"item\",\"verified\"]]],null,{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-check-circle-o text-info\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"]],\"parameters\":[]},null],[1,[24,[\"item\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"building/building-rate\"],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/building/building-list-item.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/join-to-osbb", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-fetch/ajax", "ember-changeset-validations/validators", "hospodari/utils/get-error-message", "hospodari/config/environment"], function (_exports, _objectSpread2, _ajax, _validators, _getErrorMessage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var leadValidations = {
    phone: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^\+380\d{9}$/,
      message: 'Невірний формат. Приклад: +380991112233'
    })]
  };
  var apartmentValidations = {
    address_apartment: [(0, _validators.validatePresence)(true)]
  };
  var signupValidations = {
    address_apartment: [(0, _validators.validatePresence)(true)],
    firstname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 2,
      max: 20
    })],
    lastname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 2,
      max: 20
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^[\w]+$/,
      message: 'Пароль може містити лише латинські літери і цифри'
    }), (0, _validators.validateLength)({
      min: 6,
      max: 16
    })]
  };

  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    leadValidations: leadValidations,
    signupValidations: signupValidations,
    apartmentValidations: apartmentValidations,
    item: null,
    apartment: null,
    step: 1,
    isTermsAccepted: true,
    lead: null,
    error: null,
    getUTMParams: function getUTMParams() {
      if (!location.search) {
        return {};
      }

      var urlQueryParams = location.search.replace('?', '').split('&').reduce(function (s, c) {
        var t = c.split('=');
        s[t[0]] = decodeURIComponent(t[1]);
        return s;
      }, {});

      if (urlQueryParams.gclid) {
        urlQueryParams.utm_source = 'google';
      } else if (urlQueryParams.fbclid) {
        urlQueryParams.utm_source = 'facebook';
      }

      return urlQueryParams;
    },
    init: function init() {
      this._super.apply(this, arguments);

      var me = this.get('me');
      var leadData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.getUTMParams()), {}, {
        title: "\u041F\u0440\u0438\u0454\u0434\u043D\u0430\u043D\u043D\u044F \u0434\u043E \u041E\u0421\u0411\u0411 #".concat(this.get('item.id'), " \"").concat(this.get('item.title'), "\""),
        phone: '',
        utm_referral: window.location.href
      });
      var signupData = {
        address_apartment: '',
        firstname: '',
        lastname: '',
        email: "",
        password: ''
      };

      if (this.get('me.signed')) {
        var _me = this.get('me');

        leadData.phone = _me.get('phone');
        signupData = _me.getProperties('firstname', 'lastname', 'email');
      }

      this.setProperties({
        lead: leadData,
        signup: signupData
      });

      if (me.get('signed')) {
        this.set('step', 2);
      }
    },
    actions: {
      submitLead: function submitLead(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            var _config$api = _environment.default.api,
                project = _config$api.project,
                host = _config$api.host,
                path = _config$api.path;
            changeset.save();
            var leadData = {
              data: {
                attributes: changeset.get('data'),
                relationships: {
                  project: {
                    data: {
                      type: 'projects',
                      id: project
                    }
                  }
                },
                type: 'leads'
              }
            };

            if (_this.get('me.signed')) {
              leadData.data.relationships.author = {
                data: {
                  type: 'users',
                  id: _this.get('me.id')
                }
              };
            }

            return (0, _ajax.default)("".concat(host).concat(path, "/leads"), {
              method: 'POST',
              // *GET, POST, PUT, DELETE, etc.
              mode: 'cors',
              headers: {
                'Content-Type': 'application/vnd.api+json'
              },
              body: JSON.stringify(leadData)
            }).then(function (lead) {
              _this.setProperties({
                lead: lead,
                step: 2
              });
            }).catch(function (data) {
              var error = Array.isArray(data.errors) ? JSON.parse(data.errors[0].detail).error.message : data.message;

              _this.set('error', (0, _getErrorMessage.default)(error));
            });
          }
        });
      },
      submitSignup: function submitSignup(changeset) {
        var _this2 = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            changeset.save();
            var login;

            if (_this2.get('me.signed')) {
              login = Promise.resolve();
            } else {
              login = _this2.get('me').signUp(changeset.get('firstname'), changeset.get('lastname'), _this2.get('lead.data.attributes.phone'), changeset.get('email'), changeset.get('password')).then(function (data) {
                if (data.error) {
                  _this2.get('me').clear();

                  throw new Error(data.error);
                } else {
                  return _this2.get('session').authenticate('authenticator:custom', changeset.get('email'), changeset.get('password'));
                }
              });
            }

            return login.then(function () {
              if (_this2.get('lead.data.id')) {
                return _this2.store.findRecord('lead', _this2.get('lead.data.id'));
              }
            }).then(function (lead) {
              var address_apartment = changeset.get('address_apartment');

              var apartment = _this2.get('store').createRecord('property', (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.get('item').getProperties('property_number', 'city', 'address', 'address_area_level_1', 'address_area_level_2', 'address_postal_code', 'address_type', 'address_name', 'address_building')), _this2.get('me').getProperties('phone', 'email')), {}, {
                property_type: 'apartment',
                address_apartment: address_apartment,
                title: "\u041A\u0432\u0430\u0440\u0442\u0438\u0440\u0430 \u2116".concat(address_apartment, " \u0432 \u041E\u0421\u0411\u0411 ").concat(_this2.get('item.title')),
                parent: _this2.get('item'),
                lead: lead
              }));

              return apartment.save();
            }).then(function (apartment) {
              _this2.setProperties({
                step: 3,
                apartment: apartment
              });
            }).catch(function (data) {
              var error = Array.isArray(data.errors) ? JSON.parse(data.errors[0].detail).error.message : data.message || data;

              _this2.set('error', (0, _getErrorMessage.default)(error));
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});
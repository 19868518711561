define("hospodari/components/order/order-item-business", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    store: Ember.inject.service(),
    // Permissions
    canEdit: Ember.computed('order.{status,author.id}', 'me.id', function () {
      var status = Ember.get(this, 'order.status');
      var authorId = parseInt(Ember.get(this, 'order.author.id'));
      var meId = parseInt(Ember.get(this, 'me.id'));
      var isStatusOk = status === 'draft';
      var isAuthorOk = authorId === meId;
      return isStatusOk && isAuthorOk;
    }),
    isTransactionPayed: Ember.computed('transactions', 'entity.id', function () {
      var isTransactionPayed = Ember.get(this, 'transactions.length') > 0;
      return isTransactionPayed;
    }),
    refusedOffer: Ember.computed('offers', 'entity.id', function () {
      var _this = this;

      return this.get('offers').find(function (offer) {
        return offer.get('entity.id') == _this.get('entity.id') && offer.get('status') == 'rejected';
      });
    }),
    isOfferMade: Ember.computed('offers', 'entity.id', 'refusedOffer', function () {
      var offers = Ember.get(this, 'offers');
      var entityId = parseInt(Ember.get(this, 'entity.id'));
      var isOfferMade = false;
      offers.forEach(function (offer) {
        var offerEntityId = parseInt(Ember.get(offer, 'entity.id'));
        var res = offerEntityId === entityId && !['rejected', 'canceled'].includes(offer.get('status'));
        if (res === true) isOfferMade = true; // console.log(entityId, offerEntityId, res);
      });
      return isOfferMade && !this.get('refusedOffer');
    }),
    isOfferAccepted: Ember.computed('offers', 'entity.id', function () {
      var offers = Ember.get(this, 'offers');
      var entityId = parseInt(Ember.get(this, 'entity.id'));
      var isOfferAccepted = false;
      offers.forEach(function (offer) {
        var offerEntityId = parseInt(Ember.get(offer, 'entity.id'));
        var offerStatus = Ember.get(offer, 'status');
        if (offerEntityId === entityId && offerStatus === 'accepted') isOfferAccepted = true; // console.log(entityId, offerEntityId, res);
      });
      return isOfferAccepted;
    }),
    isOfferRejected: Ember.computed('offers', 'entity.id', function () {
      var offers = Ember.get(this, 'offers');
      var entityId = parseInt(Ember.get(this, 'entity.id'));
      var isOfferRejected = false;
      offers.forEach(function (offer) {
        var offerEntityId = parseInt(Ember.get(offer, 'entity.id'));
        var offerStatus = Ember.get(offer, 'status');
        if (offerEntityId === entityId && offerStatus === 'rejected') isOfferRejected = true; // console.log(entityId, offerEntityId, res);
      });
      return isOfferRejected;
    }),
    isStatusOpened: Ember.computed('order.status', function () {
      var status = Ember.get(this, 'order.status');
      return ['opened'].indexOf(status) > -1;
    }),
    isStatusAccepted: Ember.computed('isStatusAccepted', function () {
      var status = Ember.get(this, 'order.status');
      return ['accepted'].indexOf(status) > -1;
    }),
    isStatusProgress: Ember.computed('isStatusProgress', function () {
      var status = Ember.get(this, 'order.status');
      return ['progress'].indexOf(status) > -1;
    }),
    isStatusFinished: Ember.computed('isStatusFinished', function () {
      var status = Ember.get(this, 'order.status');
      return ['finished'].indexOf(status) > -1;
    }),
    applyAmount: Ember.computed('order.amount', 'entity.is_active', function () {
      var amount = parseFloat(this.get('order.amount'));
      return !this.get('entity.is_active') && amount >= 50000 ? 0.05 * amount : 0;
    }),
    isBalanceOk: Ember.computed('entity.balance', 'applyAmount', function () {
      var balance = parseFloat(Ember.get(this, 'entity.balance'));
      var applyAmount = parseFloat(Ember.get(this, 'applyAmount'));
      return !applyAmount || balance >= applyAmount;
    }),
    askForMoneyToApply: Ember.computed('isStatusOpened', 'isBalanceOk', 'isTransactionPayed', 'entity.{blacklisted,balance}', 'order.amount', 'isOfferMade', 'refusedOffer', function () {
      var isStatusOpened = Ember.get(this, 'isStatusOpened');
      var blacklisted = Ember.get(this, 'entity.blacklisted');
      var isOfferMade = Ember.get(this, 'isOfferMade');
      var isBalanceOk = Ember.get(this, 'isBalanceOk');
      var refusedOffer = Ember.get(this, 'refusedOffer');
      var isTransactionPayed = Ember.get(this, 'isTransactionPayed');
      return !isTransactionPayed && isStatusOpened && !blacklisted && !isOfferMade && !isBalanceOk && !refusedOffer;
    }),
    balanceDiffToApply: Ember.computed('entity.balance', 'applyAmount', function () {
      var balance = parseFloat(Ember.get(this, 'entity.balance'));
      var applyAmount = parseFloat(Ember.get(this, 'applyAmount'));
      return applyAmount > balance ? applyAmount - balance : 0;
    }),
    canApply: Ember.computed('isStatusOpened', 'isBalanceOk', 'entity.{blacklisted,balance}', 'order.amount', 'isOfferMade', function () {
      var isStatusOpened = Ember.get(this, 'isStatusOpened');
      var blacklisted = Ember.get(this, 'entity.blacklisted');
      var isOfferMade = Ember.get(this, 'isOfferMade');
      var isBalanceOk = Ember.get(this, 'isBalanceOk');
      return isStatusOpened && !blacklisted && !isOfferMade && isBalanceOk;
    }),
    hasEntityservice: Ember.computed('entityservices.@each.id', 'order.service.id', function () {
      var _this2 = this;

      return this.get('entityservices').any(function (es) {
        return es.get('service.id') == _this2.get('order.service.id');
      });
    }),
    canUseBalance: Ember.computed('isStatusOpened', 'entity.blacklisted', 'isBalanceOk', 'isTransactionPayed', 'isOfferMade', function () {
      var isStatusOpened = Ember.get(this, 'isStatusOpened');
      var isBalanceOk = Ember.get(this, 'isBalanceOk');
      var isTransactionPayed = Ember.get(this, 'isTransactionPayed');
      var isOfferMade = Ember.get(this, 'isOfferMade');
      var blacklisted = Ember.get(this, 'entity.blacklisted');
      return isStatusOpened && !isTransactionPayed && isBalanceOk && !blacklisted && !isOfferMade;
    }),
    canOffer: Ember.computed('isStatusOpened', 'entity.blacklisted', 'isTransactionPayed', 'isOfferMade', 'refusedOffer', function () {
      var isStatusOpened = Ember.get(this, 'isStatusOpened');
      var isTransactionPayed = Ember.get(this, 'isTransactionPayed');
      var isOfferMade = Ember.get(this, 'isOfferMade');
      var blacklisted = Ember.get(this, 'entity.blacklisted');
      return isStatusOpened && isTransactionPayed && !blacklisted && !isOfferMade && !this.get('refusedOffer');
    }),
    canRefuse: Ember.computed('canOffer', function () {
      return this.get('canOffer');
    }),
    canViewDetails: Ember.computed('isTransactionPayed', 'refusedOffer', function () {
      var isTransactionPayed = Ember.get(this, 'isTransactionPayed');
      var refusedOffer = Ember.get(this, 'refusedOffer');
      return isTransactionPayed && !refusedOffer;
    }),
    isOffered: Ember.computed('isStatusOpened', 'isOfferMade', function () {
      var isStatusOpened = Ember.get(this, 'isStatusOpened');
      var isOfferMade = Ember.get(this, 'isOfferMade');
      return isStatusOpened && isOfferMade;
    }),
    isAccepted: Ember.computed('isStatusAccepted', 'isOfferAccepted', function () {
      var isStatusAccepted = Ember.get(this, 'isStatusAccepted');
      var isOfferAccepted = Ember.get(this, 'isOfferAccepted');
      return isStatusAccepted && isOfferAccepted;
    }),
    isRejected: Ember.computed('isStatusAccepted', 'isOfferRejected', function () {
      var isStatusAccepted = Ember.get(this, 'isStatusAccepted');
      var isOfferRejected = Ember.get(this, 'isOfferRejected');
      return isStatusAccepted && isOfferRejected;
    }),
    isProgress: Ember.computed('isStatusProgress', function () {
      var isStatusProgress = Ember.get(this, 'isStatusProgress');
      return isStatusProgress;
    }),
    isFinished: Ember.computed('isStatusFinished', function () {
      var isStatusFinished = Ember.get(this, 'isStatusFinished');
      return isStatusFinished;
    }),
    isReloading: Ember.computed('model.isLoading', function () {
      var isLoading = Ember.get(this, 'model.isLoading');
      return isLoading;
    }),
    // Actions
    actions: {
      topup: function topup() {
        var _this3 = this;

        var store = Ember.get(this, 'store');
        var order = Ember.get(this, 'order');
        var entity = Ember.get(this, 'entity');
        var customer = Ember.get(this, 'customer');
        var project = Ember.get(this, 'project');
        var product = Ember.get(this, 'product');
        var amount, description;
        amount = this.get('balanceDiffToApply');
        description = "\u0406\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u0456 \u043F\u043E\u0441\u043B\u0443\u0433\u0438 \u0414\u0435\u043F\u043E\u0437\u0438\u0442 \u041F\u043B\u044E\u0441 \u0437\u0430 \u043E\u0431\u0440\u043E\u0431\u043A\u0443 \u0443\u0433\u043E\u0434\u0438 #".concat(order.id);
        var invoice = store.createRecord('invoice', {
          seller: 'Hospodari Ukraine',
          fullname: entity.title,
          address: entity.address,
          email: entity.email,
          phone: entity.phone,
          amount: amount,
          currency: 'UAH',
          lines: Ember.A([Ember.Object.create({
            description: description,
            price: amount
          })]),
          customer: customer,
          project: project,
          product: product,
          order: order
        });
        Ember.set(this, 'isPayingViaCard', true);
        return invoice.save().then(function () {
          var paymentUrl = invoice.paymentUrl;
          window.open(paymentUrl, '_blank');
          Ember.set(_this3, 'isPayingViaCard', false);
          Ember.set(_this3, 'isAskingForReload', true);
          Ember.get(_this3, 'router').transitionTo('my.business.invoices', entity.id);
        });
      },
      topdown: function topdown() {
        var _this4 = this;

        if (!this.isCashbackTermsAccepted) {
          this.set('isCashbackTermsTooltipVisible', true);
          return;
        }

        var store = Ember.get(this, 'store');
        var order = Ember.get(this, 'order');
        var entity = Ember.get(this, 'entity');
        var customer = Ember.get(this, 'customer');
        var project = Ember.get(this, 'project');
        var product = Ember.get(this, 'product');
        var amount, description; // amount = order.apply_amount;

        amount = 0;
        description = "\u0421\u043F\u0438\u0441\u0430\u043D\u043D\u044F \u043A\u043E\u043C\u0456\u0441\u0441\u0456\u0457 \u0437\u0430 \u043E\u0431\u0440\u043E\u0431\u043A\u0443 \u0443\u0433\u043E\u0434\u0438 #".concat(order.id);
        var transaction = store.createRecord('transaction', {
          transaction_type: 'credit',
          amount: amount,
          description: description,
          customer: customer,
          project: project,
          product: product,
          order: order,
          entity: entity
        });
        Ember.set(this, 'isPaying', true);
        return transaction.save().then(function () {
          Ember.get(_this4, 'onTopDown')();
        }).catch(function () {
          window.location.reload();
        }).finally(function () {
          return _this4.set('isPaying', false);
        });
      },
      refuse: function refuse(customerResponseText) {
        var _this5 = this;

        // create rejected offer and save action with customer response
        var order = this.order,
            entity = this.entity,
            project = this.project,
            product = this.product;
        this.setProperties({
          isRefusing: true,
          isRefuseShown: false
        });
        return this.store.createRecord('offer', {
          amount: 0,
          status: 'rejected',
          order: order,
          entity: entity,
          project: project,
          product: product
        }).save().then(function () {
          var action = _this5.store.createRecord('action', {
            text: 'Виконавець відмовився від угоди. Причина:\n ' + customerResponseText,
            action_type: 'system',
            action_direction: 'in',
            manager: _this5.get('me.user'),
            customer: order.get('author'),
            author: _this5.get('me.user'),
            project: project,
            product: product,
            entity: entity,
            order: order
          });

          return action.save();
        }).then(function () {
          return Ember.get(_this5, 'onTopDown')();
        }).finally(function () {
          return _this5.set('isRefusing', false);
        });
      },
      reload: function reload() {
        Ember.get(this, 'onTopDown')();
      },
      offer: function offer() {
        var order = Ember.get(this, 'order');
        var entity = Ember.get(this, 'entity');
        Ember.get(this, 'router').transitionTo('my.business.offer', entity.id, order.id);
      }
    }
  });

  _exports.default = _default;
});
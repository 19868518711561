define("hospodari/components/business/business-service-form", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "hospodari/config/environment"], function (_exports, _objectSpread2, _toConsumableArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var serviceTypes = (0, _toConsumableArray2.default)(_environment.default.service.types);

  var _default = Ember.Component.extend({
    serviceTypes: serviceTypes,
    loadServices: function loadServices(serviceType) {
      var _this = this;

      this.set('services', []);
      return this.get('store').query('service', {
        page: 1,
        per_page: 100,
        'filter[display]': 'public',
        'filter[service_type]': serviceType,
        sort: '-title'
      }).then(function (services) {
        return _this.set('services', services);
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        var _this2 = this;

        return item.validate().then(function () {
          if (item.get('isValid')) {
            item.save();
            var itemData = item.get('data');
            return _this2.get('onSubmit')((0, _objectSpread2.default)((0, _objectSpread2.default)({}, itemData), {}, {
              service_type: itemData.service_type.value
            }));
          }
        });
      },
      handleCityDetailsChange: function handleCityDetailsChange(details) {
        this.get('item').setProperties(details ? Ember.getProperties(details, 'address_area_level_1', 'address_area_level_2', 'address_postal_code') : {
          address_area_level_1: null,
          address_area_level_2: null,
          address_postal_code: null
        });
      },
      handleServiceTypeChange: function handleServiceTypeChange(serviceType) {
        this.set('item.service_type', serviceType);
        this.set('item.service', null);
        this.loadServices(serviceType);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/signup-owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var store = this.get('store');
      return store.createRecord('user', {// firstname: 'John',
        // lastname: 'Doe',
        // phone: '0503214075',
        // email: 'test1@livarava.com',
        // password: 'hospodari123',
      });
    }
  });

  _exports.default = _default;
});
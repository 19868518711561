define("hospodari/routes/my/business/new-service", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var confirmText = 'Ви впевнені, що хочете припинити додавання нової категорії для бізнесу?';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        item: store.findRecord('entity', params.id),
        entityService: Promise.resolve({})
      });
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.setProperties({
          hasChanges: false
        });
      }
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      },
      handleAdded: function handleAdded(entityService) {
        this.transitionTo('my.business.services', entityService.get('entity.id'));
      },
      willTransition: function willTransition(transition) {
        if (this.controller.hasChanges && !confirm(confirmText)) {
          transition.abort();
        } else {
          // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/building/building-order-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    listType: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('listType', this.get('media.isMobile') ? 'tiles' : 'table');
    }
  });

  _exports.default = _default;
});
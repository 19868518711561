define("hospodari/components/seo-link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    route: null,
    isCurrent: Ember.computed('router.currentURL', 'route', 'model', function () {
      var _this$getProperties = this.getProperties('router', 'route', 'model'),
          router = _this$getProperties.router,
          route = _this$getProperties.route,
          model = _this$getProperties.model;

      var url;

      if (model) {
        url = router.urlFor(route, model);
      } else {
        url = router.urlFor(route);
      }

      return router.get('currentURL') == url;
    })
  }).reopenClass({
    positionalParams: ['route', 'model']
  });

  _exports.default = _default;
});
define("hospodari/components/business/business-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orders: null,
    isLoading: false,
    fetchOrders: function fetchOrders() {
      var _this = this;

      var store = this.get('store');
      this.set('isLoading', true);
      return store.query('entityservice', {
        'filter[entity]': this.get('item.id'),
        page: 1,
        per_page: 100
      }).then(function (esItems) {
        return Ember.RSVP.allSettled(esItems.map(function (es) {
          return store.query('order', {
            include: 'service',
            'filter[project]': _this.get('project.id'),
            'filter[product]': _this.get('product.id'),
            'filter[status]': 'opened',
            'filter[city]': es.get('city'),
            'filter[service]': es.get('service.id'),
            // 10 or up to 100 depending on entityservices amount
            per_page: Math.max(10, Math.round(100 / esItems.length))
          });
        }));
      }).then(function (results) {
        if (_this.isDestroyed) {
          return;
        }

        _this.setProperties({
          isLoading: false,
          orders: []
        });

        results.filterBy('state', 'fulfilled').mapBy('value').forEach(function (orders) {
          return _this.get('orders').pushObjects(orders.toArray());
        });
        return _this.get('orders');
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchOrders();
    }
  });

  _exports.default = _default;
});
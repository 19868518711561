define("hospodari/templates/components/building/building-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Qbu+Rqt",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-bordered\"],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"th\",true],[8],[0,\"Об'єкт\"],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[0,\"Тип\"],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[0,\"Адреса\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"my.buildings.item\",[29,[[23,1,[\"property\",\"id\"]]]]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[\"property\",\"title\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[1,[23,1,[\"property\",\"property_title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[1,[23,1,[\"property\",\"address\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/building/building-table.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/webinar/webinar-item", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    now: (0, _moment.default)(),
    datetime: (0, _moment.default)("2020-04-24T18:00:00"),
    days: Ember.computed('now', 'datetime', function () {
      var now = Ember.get(this, 'now');
      var datetime = Ember.get(this, 'datetime');
      var sub = datetime.clone();
      var days = Math.max(sub.diff(now, 'days'), 0);
      return days < 10 ? "0" + days : days;
    }),
    hours: Ember.computed('now', 'datetime', function () {
      var now = Ember.get(this, 'now');
      var datetime = Ember.get(this, 'datetime');
      var sub = datetime.clone();
      var hours = Math.max(sub.subtract(datetime.diff(now, 'days'), 'days').diff(now, 'hours'), 0);
      return hours < 10 ? "0" + hours : hours;
    }),
    minutes: Ember.computed('now', 'datetime', function () {
      var now = Ember.get(this, 'now');
      var datetime = Ember.get(this, 'datetime');
      var sub = datetime.clone();
      var minutes = Math.max(sub.subtract(sub.diff(now, 'hours'), 'hours').diff(now, 'minutes'), 0);
      return minutes < 10 ? "0" + minutes : minutes;
    }),
    seconds: Ember.computed('now', 'datetime', function () {
      var now = Ember.get(this, 'now');
      var datetime = Ember.get(this, 'datetime');
      var sub = datetime.clone();
      var seconds = Math.max(sub.subtract(sub.diff(now, 'minutes'), 'minutes').diff(now, 'seconds'), 0);
      return seconds < 10 ? "0" + seconds : seconds;
    }),
    updateNow: function updateNow() {
      var _this = this;

      Ember.set(this, 'now', (0, _moment.default)());
      Ember.run.later(function () {
        _this.updateNow();
      }, 1000);
    },
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'now', (0, _moment.default)());
      this.updateNow();
    }
  });

  _exports.default = _default;
});
define("hospodari/components/building/building-members-list-item", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    roles: _environment.default.building.roles,
    role: Ember.computed('item.role', 'roles', function () {
      var roles = Ember.get(this, 'roles');
      var role = Ember.get(this, 'item.role');
      var selected;
      roles.forEach(function (r) {
        if (r.value === role) {
          selected = r;
        }
      });
      return selected;
    }),
    actions: {
      edit: function edit() {
        Ember.set(this, 'editMode', true);
      },
      close: function close() {
        Ember.set(this, 'editMode', false);
      },
      save: function save() {
        var _this = this;

        var item = Ember.get(this, 'item');
        Ember.set(this, 'item.role', Ember.get(this, 'role.value'));
        item.save().then(function () {
          Ember.set(_this, 'editMode', false);
          Ember.get(_this, 'onUserChanged')();
        });
      },
      delete: function _delete() {
        var _this2 = this;

        var item = Ember.get(this, 'item');
        item.destroyRecord().then(function () {
          Ember.get(_this2, 'onUserDeleted')();
        });
      },
      setRole: function setRole(role) {
        Ember.set(this, 'role', role);
      }
    }
  });

  _exports.default = _default;
});
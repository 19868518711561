define("hospodari/controllers/my/orders/new", ["exports", "hospodari/validations/order-validations"], function (_exports, _orderValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['property', 'service', 'entity', 'step', 'subStep'],
    orderValidations: _orderValidations.default,
    property: null,
    service: null,
    entity: null,
    step: 0,
    subStep: 1,
    hasChanges: false
  });

  _exports.default = _default;
});
define("hospodari/templates/components/review/input-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nVFBR9BV",
    "block": "{\"symbols\":[\"number\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex align-items-center justify-content-between mb-1\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[1,10,true],null]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-outline-primary mr-1 \",[28,\"if\",[[28,\"eq\",[[24,[\"value\"]],[23,1,[]]],null],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"handleChange\",[23,1,[]]]],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"small text-muted d-flex align-items-center justify-content-between mb-1\"],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"Погано\"],[9],[7,\"span\",true],[8],[0,\"Відмінно!\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/review/input-rating.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/my/business/buy", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        item: store.findRecord('entity', params.id),
        customer: store.findRecord('neuron', params.id),
        project: store.findRecord('project', _environment.default.api.project),
        product: store.findRecord('product', _environment.default.api.product),
        rates: fetch('https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json').then(function (response) {
          return response.json();
        })
      });
    },
    actions: {
      invoiceCreated: function invoiceCreated(id) {
        this.transitionTo('my.business.invoices', id);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/business/business-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XvDi/bf6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card business-services p-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"business-aside col-12 col-md-3\"],[8],[0,\"\\n      \"],[1,[28,\"business/business-navigation\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n      \"],[1,[28,\"business/business-activation-message\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"mt-2 text-muted\"],[8],[0,\"\\n        \"],[7,\"small\",true],[8],[0,\"\\n          \"],[1,[22,\"roleSummary\"],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n        \"],[5,\"link-to\",[[12,\"class\",\"btn btn-custom\"]],[[\"@route\",\"@model\"],[\"my.business.new-service\",[24,[\"item\",\"id\"]]]],{\"statements\":[[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n          Додати категорію послуг\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[1,[28,\"business/business-services-list\",null,[[\"items\",\"onEntityServiceDeleted\"],[[24,[\"items\"]],[24,[\"onEntityServiceDeleted\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-services.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/initializers/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'router',
    initialize: function initialize(application) {
      application.inject('route', 'router', 'service:router');
      application.inject('controller', 'router', 'service:router');
      application.inject('component', 'router', 'service:router');
    }
  };
  _exports.default = _default;
});
define("hospodari/transforms/datetime", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value ? value.utc().toJSON() : null;
    },
    deserialize: function deserialize(value) {
      return _moment.default.tz(value, 'UTC');
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/my/buildings/index", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Моя нерухомість',
    // Model
    model: function model() {
      var me = Ember.get(this, 'me');
      var store = Ember.get(this, 'store');
      var userId = parseInt(me.id);
      var items = store.query('propertyuser', {
        'filter[user]': userId,
        page: 1,
        per_page: 100
      });
      return Ember.RSVP.hash({
        items: items
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/signin-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    // Params
    error: null,
    isLoading: false,
    email: '',
    password: '',
    actions: {
      signin: function signin() {
        var _this = this;

        // Services
        var me = Ember.get(this, 'me');
        var session = Ember.get(this, 'session'); // Params

        var email = Ember.get(this, 'email').trim();
        var password = Ember.get(this, 'password');
        var result = me.signIn(email, password);
        Ember.set(this, 'error', null);
        Ember.set(this, 'isLoading', true);
        result.then(function (data) {
          session.authenticate('authenticator:custom', email, password).then(function () {
            if (_this.isDestroyed) {
              return;
            }

            Ember.set(_this, 'isLoading', false);
            var next = Ember.get(_this, 'model.next');

            if (window && next) {
              window.open(next, '_self');
            } else {
              var _me = Ember.get(_this, 'me');

              _me.redirectAfterSignIn();
            }
          }).catch(function (response) {
            if (_this.isDestroyed) {
              return;
            }

            var error = response.error || 'Помилка. Будь ласка, зверніться до admin@livarava.com';

            if (error === 'Invalid login or password') {
              error = 'Невірний логін чи пароль';
            }

            Ember.set(_this, 'isLoading', false);
            Ember.set(_this, 'error', error);
          });
        }).catch(function (error) {
          if (_this.isDestroyed) {
            return;
          }

          _this.set('isLoading', false);

          _this.set('error', 'Помилка. Будь ласка, зверніться до admin@livarava.com');
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/order/order-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JicUrA4/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"text-muted text-small\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[24,[\"order\",\"views\"]],false],[0,\" переглядів\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"text-muted text-small\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-briefcase\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[24,[\"order\",\"apply_competitors\"]],false],[0,\" виконавців\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"text-muted text-small\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-handshake-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[24,[\"offers\",\"length\"]],false],[0,\" пропозицій\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/order/order-stats.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('email', null);
    },
    // Actions
    actions: {
      toRestore: function toRestore(email) {
        this.transitionTo('restore', {
          queryParams: {
            email: email
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/validations/business-validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateFormat)({
      regex: /^[^"']+$/,
      message: "Вкажіть назву без лапок",
      allowBlank: true
    })],
    summary: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    description: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    })],
    city: [(0, _validators.validatePresence)(true)],
    address_type: [(0, _validators.validatePresence)(true)],
    address_name: [(0, _validators.validatePresence)(true)],
    address_building: [(0, _validators.validatePresence)(true)],
    business_id: [(0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^(?=[0-9]*$)(?:.{8}|.{10})$/,
      message: "ЄДРПОУ повинен складатися з 8-ми цифр, а ІПН - з 10-ти"
    })],
    phone: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^\+380\d{9}$/,
      message: "Невірний формат. Приклад: +380991112233"
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: "email"
    })],
    website: [(0, _validators.validateFormat)({
      allowBlank: true,
      type: "url"
    })]
  };
  _exports.default = _default;
});
define("hospodari/templates/components/business/business-services-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y2nnrmEH",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row mt-4 mb-4\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-4\"],[8],[0,\"\\n\\n      \"],[1,[28,\"business/business-services-list-item\",null,[[\"item\",\"onEntityServiceDeleted\"],[[23,1,[]],[24,[\"onEntityServiceDeleted\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-services-list.hbs"
    }
  });

  _exports.default = _default;
});
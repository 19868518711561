define("hospodari/components/building/building-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    emergencyServices: null,
    subscriptionServices: null,
    propertyservices: null,
    serviceToToggle: null,
    search: '',
    debouncedSearch: null,
    showActiveServices: false,
    activePropertyservices: Ember.computed.filterBy('propertyservices', 'status', 'active'),
    visibleSubscriptionServices: Ember.computed('subscriptionServices.@each.title', 'activePropertyservices.@each.service', 'showActiveServices', 'debouncedSearch', function () {
      var q = this.get('debouncedSearch');
      var showActiveServices = this.get('showActiveServices');
      var filteredServices = this.get('subscriptionServices'); // ToDo implement filtering by `active` state

      if (showActiveServices) {
        var propertyservices = this.get('activePropertyservices') || [];
        filteredServices = propertyservices.filter(function (ps) {
          return ps.get('service.service_type') == 'subscription';
        }).map(function (ps) {
          return ps.get('service');
        });
      }

      if (!q) return filteredServices;
      var query = q.toLowerCase();
      return filteredServices.filter(function (service) {
        return service.get('title').toLowerCase().includes(query);
      });
    }),
    updateSearch: function updateSearch(q) {
      this.set('debouncedSearch', q);
    },
    actions: {
      toggleService: function toggleService(service, propertyservice) {
        var _this = this;

        // ToDo: toggle service and save data
        var property = this.get('item');

        if (!propertyservice) {
          var _propertyservice = this.get('store').createRecord('propertyservice', {
            service: service,
            property: property,
            status: 'active',
            amount: service.get('amount')
          });

          return _propertyservice.save().then(function () {
            if (typeof _this.get('reload') == 'function') {
              _this.get('reload')();
            }
          });
        } else {
          propertyservice.set('status', propertyservice.get('status') == 'active' ? 'paused' : 'active');
          return propertyservice.save();
        }
      },
      handleSearch: function handleSearch(q) {
        Ember.run.debounce(this, this.updateSearch, q, 100); // make search empty on "Clear" button click

        if (!q) this.set('search', null);
      },
      toggleActive: function toggleActive() {
        this.toggleProperty('showActiveServices');
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/service/input-service-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9/IolzHQ",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select\",null,[[\"placeholder\",\"tagName\",\"triggerClass\",\"dropdownClass\",\"searchEnabled\",\"options\",\"onChange\",\"selected\",\"allowClear\"],[[24,[\"placeholder\"]],[24,[\"tagName\"]],[24,[\"triggerClass\"]],[24,[\"dropdownClass\"]],false,[24,[\"options\"]],[28,\"action\",[[23,0,[]],\"handleChange\"],null],[24,[\"selected\"]],true]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/service/input-service-type.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/tell-me-why", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // attributes
    answers: null,
    allowOther: true,
    question: "Вкажіть будь-ласка причину",
    // data
    answer: null,
    otherValue: 'other',
    otherAnswer: null,
    onSubmit: function onSubmit() {},
    onCancel: function onCancel() {},
    value: Ember.computed('answer', 'otherValue', 'otherAnswer', function () {
      var _this$getProperties = this.getProperties('answer', 'otherValue', 'otherAnswer'),
          answer = _this$getProperties.answer,
          otherValue = _this$getProperties.otherValue,
          otherAnswer = _this$getProperties.otherAnswer;

      return answer == otherValue ? otherAnswer : answer;
    }),
    actions: {
      submit: function submit() {
        var value = this.get('value');

        if (!value) {
          return;
        }

        return this.onSubmit(value);
      },
      cancel: function cancel() {
        return this.onCancel();
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/service/service-post-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "amDf/dMX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\"],[\"blog.item\",[24,[\"item\",\"id\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"card service-post-item\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-img-top-custom\"],[11,\"style\",[24,[\"item\",\"imageStyle\"]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"date mb-1\"],[8],[1,[28,\"moment-format\",[[24,[\"item\",\"created\"]],\"DD.MM.YYYY\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title mb-3\"],[8],[0,\"\\n        \"],[1,[24,[\"item\",\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"summary mb-3\"],[8],[1,[24,[\"item\",\"summary\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/service/service-post-item.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/business/business-offer-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    // Actions
    actions: {
      click: function click(offer) {
        var orderId = parseInt(Ember.get(offer, 'order.id'));
        var entityId = parseInt(Ember.get(this, 'item.id'));
        Ember.get(this, 'router').transitionTo('my.business.order', entityId, orderId);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/validations/entityservice-validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    service_type: [(0, _validators.validatePresence)(true)],
    service: [(0, _validators.validatePresence)(true)],
    city: [(0, _validators.validatePresence)(true)],
    amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      gte: 300,
      lt: 10e9
    })],
    cashback: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      gte: 5,
      lte: 100
    })],
    customer_max: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      integer: true,
      gte: 1,
      lt: 10e7
    })]
  };
  _exports.default = _default;
});
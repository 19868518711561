define("hospodari/components/contacts/chat-bots", ["exports", "hospodari/utils/gtag_report_conversion"], function (_exports, _gtag_report_conversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      handleClick: function handleClick() {
        (0, _gtag_report_conversion.default)('AW-616760782/CIWeCKv8qPwCEM6LjKYC');
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/application", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),
    api: Ember.inject.service(),
    title: function title(tokens) {
      if (tokens && tokens.length > 0) return tokens.join(' - ') + ' - Господарі';
      return 'Господарі';
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var router = this.router;
      router.on('routeDidChange', function () {
        var page = router.currentURL;
        var title = router.currentRouteName || 'unknown'; // track production wesite only

        if (window.location.hostname === 'www.hospodari.com') {
          _this.metrics.trackPage({
            page: page,
            title: title
          });
        }
      });
      var url = "".concat(_environment.default.api.host).concat(_environment.default.api.path, "/");
      fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }).then(function (response) {
        // let message = response.json();
        response.json().then(function (object) {
          // console.log(object.version);
          Ember.set(_this, 'api.version', object.version);
        });
      });
    }
  });

  _exports.default = _default;
});
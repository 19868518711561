define("hospodari/templates/components/files/logo-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7A13m0l",
    "block": "{\"symbols\":[\"error\",\"queue\"],\"statements\":[[4,\"if\",[[24,[\"liva\",\"logo_url\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"liva\",\"logo_url\"]]]]],[10,\"alt\",\"Логотип\"],[10,\"class\",\"img-thumbnail img-fluid\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"image mb-1 text-center\"],[11,\"title\",[29,[[24,[\"item\",\"title\"]]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"ho ho-portfolio\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[5,\"file-upload\",[[12,\"class\",\"mb-0\"]],[[\"@name\",\"@name\",\"@accept\",\"@multiple\",\"@onfileadd\"],[\"files\",\"photos\",[22,\"accept\"],false,[28,\"action\",[[23,0,[]],\"upload\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"small text-center\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[\"files\",\"length\"]]],null,{\"statements\":[[0,\"      Завантаження...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fa fa-pencil\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      Змінити логотип\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[2]}],[0,\"\\n\"],[4,\"if\",[[24,[\"errors\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-danger small\"],[10,\"role\",\"alert\"],[8],[0,\"\\n    Помилка при завантаженні файлy\\n\"],[4,\"each\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"br\",true],[8],[9],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/files/logo-uploader.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/input-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Attributes
    klass: null,
    value: null,
    items: null,
    item: null,
    klassComputed: Ember.computed('klass', function () {
      var klass = Ember.get(this, 'klass');
      return "".concat(klass, " form-control-service");
    }),
    valueChanged: Ember.observer('value', function () {
      var value = Ember.get(this, 'value');
      Ember.set(this, 'showOptions', true);
      Ember.get(this, 'onSelect')(null);
      Ember.get(this, 'onChange')(value);
      Ember.run.debounce(this, this.search, value, 500);
    }),
    search: function search(q) {
      var _this = this;

      if (q.length < 3) {
        Ember.set(this, 'showOptions', false);
        return;
      }

      var store = Ember.get(this, 'store');
      store.query('service', {
        q: q,
        'filter[display]': 'public',
        'filter[service_type]': 'job'
      }).then(function (data) {
        Ember.set(_this, 'items', data);
      });
    },
    actions: {
      select: function select(item) {
        Ember.set(this, 'item', item);
        Ember.set(this, 'value', item.title);
        Ember.set(this, 'showOptions', false);
        Ember.get(this, 'onSelect')(item);
      }
    }
  });

  _exports.default = _default;
});
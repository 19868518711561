define("hospodari/models/entity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Neuron
    type: _emberData.default.attr('string'),
    // Stats
    weight: _emberData.default.attr('number'),
    views: _emberData.default.attr('number'),
    subscriber_count: _emberData.default.attr('number'),
    // Subscribers count
    comment_count: _emberData.default.attr('number'),
    // Comments count
    axon_count: _emberData.default.attr('number'),
    // Axons count
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    verified: _emberData.default.attr('datetime'),
    activated: _emberData.default.attr('datetime'),
    blacklisted: _emberData.default.attr('datetime'),
    // Business
    title: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    business_id: _emberData.default.attr('string'),
    balance: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    funnel: _emberData.default.attr('string', {
      defaultValue: 'new'
    }),
    rating: _emberData.default.attr('number'),
    rating_count: _emberData.default.attr('number'),
    // Address
    city: _emberData.default.attr('string'),
    // місто
    address: _emberData.default.attr('string'),
    // deprecated - старе поле адреси, більше не використовується
    address_area_level_1: _emberData.default.attr('string'),
    // область
    address_area_level_2: _emberData.default.attr('string'),
    // район
    address_postal_code: _emberData.default.attr('string'),
    // поштовий індекс
    address_type: _emberData.default.attr('string'),
    // тип вулиці
    address_name: _emberData.default.attr('string'),
    // назва вулиці
    address_building: _emberData.default.attr('string'),
    // номер будинку
    address_apartment: _emberData.default.attr('string'),
    // номер квартири
    // Contacts
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    // Images
    icon_url: _emberData.default.attr('string'),
    image_url: _emberData.default.attr('string'),
    logo_url: _emberData.default.attr('string'),
    // Flags
    is_active: _emberData.default.attr('boolean'),
    is_contractor: _emberData.default.attr('boolean'),
    is_provider: _emberData.default.attr('boolean'),
    is_performer: _emberData.default.attr('boolean'),
    has_permission: _emberData.default.attr('boolean'),
    // Author
    author: _emberData.default.belongsTo('user'),
    manager: _emberData.default.belongsTo('user'),
    lead: _emberData.default.belongsTo('lead'),
    logo: _emberData.default.belongsTo('neuron'),
    // Rights
    rights: _emberData.default.attr('plain-object')
  });

  _exports.default = _default;
});
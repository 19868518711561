define("hospodari/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // Services
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    me: Ember.inject.service(),
    restore: function restore(data) {
      var me = Ember.get(this, 'me');
      var dataToken = data.token || data.data.session;
      var sessionToken = Ember.get(this, 'session.token');
      var cookiesToken = Ember.get(this, 'cookies').read('token');
      var meToken = Ember.get(this, 'me.token');
      var token = dataToken || sessionToken || cookiesToken || meToken; // Ember.Logger.log('Restoring session...');
      // Ember.Logger.log(`Token: ${token}`);
      // noinspection UnnecessaryLocalVariableJS

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        me.load(token).then(function (response) {
          resolve(response);
        }).catch(function (response) {
          reject(response);
        });
      });
      return promise;
    },
    authenticate: function authenticate(login, password) {
      var me = Ember.get(this, 'me'); // noinspection UnnecessaryLocalVariableJS

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        me.signIn(login, password).then(function (response) {
          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }).catch(function (response) {
          reject(response);
        });
      });
      return promise;
    },
    invalidate: function invalidate() {
      var me = Ember.get(this, 'me'); // noinspection UnnecessaryLocalVariableJS

      var promise = new Ember.RSVP.Promise(function (resolve) {
        me.signOut().then(function (data) {
          resolve(data);
        });
      });
      return promise;
    }
  });

  _exports.default = _default;
});
define("hospodari/utils/get-service-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getServiceIcon;

  function getServiceIcon(title) {
    var icons = {};
    icons["Водовідведення"] = "ho-plumbing";
    icons["Водопостачання"] = "ho-faucet";
    icons["Опалення"] = "ho-radiator";
    icons["Електрика"] = "ho-light-bulb";
    icons["Ліфти"] = "ho-elevator";
    icons["Прибирання"] = "ho-broom";
    icons["Енергоефективність"] = "ho-energy-class";
    icons["Вікна, двері"] = "ho-window";
    var icon = icons[title];
    return title in icons ? icon : "";
  }
});
define("hospodari/templates/components/seo-link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FVyoP1N0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"route\",\"model\",\"comp\"],[[24,[\"route\"]],[24,[\"model\"]],[28,\"component\",[[28,\"if\",[[24,[\"isCurrent\"]],\"empty-tag\",\"link-to\"],null]],[[\"class\"],[\"a\"]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/seo-link-to.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/orders/item", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.order.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var order = store.findRecord('order', params.id);
      return Ember.RSVP.hash({
        order: order
      });
    },
    afterModel: function afterModel(model, transition) {
      var order = model.order;
      Ember.set(this, 'titleToken', order.title);
    }
  });

  _exports.default = _default;
});
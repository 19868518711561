define("hospodari/templates/signup-building-private-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "awcMur4V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"building/signup-building-complete\",null,[[\"title\"],[\"Ваш будинок додано!\"]],{\"statements\":[[0,\"\\t\"],[7,\"p\",true],[8],[0,\"Ви успішно додали свій будинок. Тепер додайте угоду.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/signup-building-private-complete.hbs"
    }
  });

  _exports.default = _default;
});
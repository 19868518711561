define("hospodari/utils/get-display-label", ["exports", "livarava-framework/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getDisplayLabel;

  function getDisplayLabel(display) {
    var options = _environment.default.displayOptions;
    var out = 'Unknown';
    options.forEach(function (optoin) {
      if (optoin.value === display) {
        out = optoin.label;
      }
    });
    return out;
  }
});
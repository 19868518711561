define("hospodari/templates/components/business/business-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L1fYbTKZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"order-list\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row mt-3 align-items-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"py-1 col-12 text-md-left\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"my-0\"],[8],[0,\"\\n        Угоди\\n        \"],[7,\"small\",true],[10,\"class\",\"text-muted\"],[8],[0,\"спеціально для вас\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row mt-4 mb-4\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center py-4\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-2x fa-circle-o-notch fa-spin text-muted\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"orders\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"business/business-order-table\",null,[[\"class\",\"item\",\"customer\",\"project\",\"product\",\"orders\",\"showObject\"],[\"col-12\",[24,[\"item\"]],[24,[\"customer\"]],[24,[\"project\"]],[24,[\"product\"]],[24,[\"orders\"]],[24,[\"showObject\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n          Скоро ми підберемо угоди для вас.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-feed.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/review/input-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    onChange: function onChange() {},
    actions: {
      handleChange: function handleChange(rating) {
        this.onChange(rating);
      },
      divide: function divide(n1, n2) {
        return n1 / n2;
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/service/service-business-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    service: null,
    city: null,
    entityservices: null,
    entities: null,
    loading: true,
    activeEntities: Ember.computed('entities.@each.entity', function () {
      var entities = this.get('entities') || [];
      return entities.filterBy('entity.is_active', true).sortBy('entity.activated').reverse();
    }),
    localActiveEntities: Ember.computed('activeEntities.@each.{entity,cities}', 'city', function () {
      var activeEntities = this.get('activeEntities') || [];
      var city = this.city;
      if (!city) return activeEntities;
      return activeEntities.filter(function (_ref) {
        var cities = _ref.cities;
        return cities.includes(city);
      });
    }),
    otherActiveEntities: Ember.computed.setDiff('activeEntities', 'localActiveEntities'),
    fetchEntities: function fetchEntities() {
      var _this = this;

      this.set('loading', true);

      var _this$getProperties = this.getProperties('store', 'service'),
          store = _this$getProperties.store,
          service = _this$getProperties.service;

      store.query('entityservice', {
        'filter[service]': service.get('id'),
        per_page: 100,
        page: 1
      }).catch(function () {
        return [];
      }).then(function (entityservices) {
        _this.set('entityservices', entityservices);

        return Ember.RSVP.hash({
          entityservices: entityservices,
          entities: Promise.all(entityservices.mapBy('entity'))
        });
      }).then(function (_ref2) {
        var entityservices = _ref2.entityservices,
            entities = _ref2.entities;
        return entities.uniqBy('id').map(function (entity) {
          return {
            entity: entity,
            cities: entityservices.filterBy('entity.id', entity.get('id')).mapBy('city').uniq()
          };
        });
      }).then(function (entities) {
        return _this.set('entities', entities);
      }).finally(function () {
        return _this.set('loading', false);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchEntities();
    }
  });

  _exports.default = _default;
});
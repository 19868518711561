define("hospodari/routes/my/business/new", ["exports", "hospodari/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Новий бізнес',
    // Model
    model: function model(params) {
      var store = this.get('store');
      return store.createRecord('entity', {
        created: (0, _moment.default)()
      });
    }
  });

  _exports.default = _default;
});
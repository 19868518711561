define("hospodari/components/service/input-service-type", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "hospodari/config/environment"], function (_exports, _toConsumableArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var serviceTypes = (0, _toConsumableArray2.default)(_environment.default.service.types);

  var _default = Ember.Component.extend({
    options: serviceTypes,
    value: null,
    selected: Ember.computed('value', function () {
      var _this = this;

      if (!this.get('value')) return null;
      return this.get('options').find(function (option) {
        return option.value == _this.get('value');
      });
    }),
    actions: {
      handleChange: function handleChange(serviceType) {
        if (typeof this.get('onChange') == 'function') {
          this.get('onChange')(serviceType.value);
        }
      }
    }
  });

  _exports.default = _default;
});
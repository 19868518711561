define("hospodari/routes/my/business/edit-offer", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Редагувати попозицію',
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var entityId = parseInt(params.id);
      var offerId = parseInt(params.oid);
      var projectId = _environment.default.api.project;
      var productId = _environment.default.api.product;
      return Ember.RSVP.hash({
        entity: store.findRecord('entity', entityId),
        customer: store.findRecord('neuron', entityId),
        offer: store.findRecord('offer', offerId),
        project: store.findRecord('project', projectId),
        product: store.findRecord('product', productId)
      });
    },
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
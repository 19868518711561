define("hospodari/templates/signup-continue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SkyzWlis",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"common/common-header\",null,[[\"search\",\"title\"],[false,\"\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container mt-4 mb-4\"],[8],[0,\"\\n\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-center mb-5\"],[8],[0,\"\\n    Вітаємо з успішною реєстрацією! \\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"mx-auto mb-4\"],[10,\"style\",\"max-width: 500px;\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Наступний крок:\"],[9],[0,\"\\n    \"],[7,\"ul\",true],[8],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"Зареєструйте будинок, якщо Ви - власник нерухомості\"],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"Зареєструйте бізнес, якщо Ви плануєте надавати послуги\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"query\",\"route\"],[\"btn btn-lg btn-primary mb-3\",[28,\"hash\",null,[[\"type\"],[null]]],\"my.buildings.new\"]],{\"statements\":[[0,\"        Зареєструвати будинок\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-lg btn-success mb-3\",\"my.business.new\"]],{\"statements\":[[0,\"        Зареєструвати бізнес\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/signup-continue.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/helpers/service-link", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fullAddress(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        service = _ref2[0],
        models = _ref2[1];

    // check arguments
    if (Ember.isBlank(service)) {
      return 'index';
    }

    var parent = service.get('parent.id');
    var serviceId = service.get('id');

    if (models) {
      return parent ? [parent, serviceId] : [serviceId];
    } else {
      return parent ? 'services.item' : 'services.category';
    }
  });

  _exports.default = _default;
});
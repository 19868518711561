define("hospodari/helpers/full-address", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fullAddress(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
        item
    /*, ...rest*/
    = _ref3[0];

    var _ref2$showCity = _ref2.showCity,
        showCity = _ref2$showCity === void 0 ? false : _ref2$showCity,
        _ref2$fallbackToAddre = _ref2.fallbackToAddress,
        fallbackToAddress = _ref2$fallbackToAddre === void 0 ? true : _ref2$fallbackToAddre,
        _ref2$emptyValue = _ref2.emptyValue,
        emptyValue = _ref2$emptyValue === void 0 ? '' : _ref2$emptyValue;

    // check arguments
    if (Ember.isBlank(item)) {
      return emptyValue;
    }

    var parts = [];

    var _ref4 = typeof item.getProperties == 'function' ? item.getProperties('address', 'city', 'address_type', 'address_name', 'address_building', 'address_apartment') : item,
        address = _ref4.address,
        city = _ref4.city,
        address_type = _ref4.address_type,
        address_name = _ref4.address_name,
        address_building = _ref4.address_building,
        address_apartment = _ref4.address_apartment; // fallback to deprecated address field


    if (fallbackToAddress && !(address_name || address_building || address_apartment)) {
      return address;
    }

    if (showCity && city) {
      parts.push(city);
    }

    if (address_type || address_name) {
      parts.push(address_type + ' ' + address_name);
    }

    if (address_building) {
      parts.push('буд. ' + address_building);
    }

    if (address_apartment) {
      parts.push('кв. ' + address_apartment);
    }

    return parts.join(', ');
  });

  _exports.default = _default;
});
define("hospodari/services/google-place-autocomplete/manager", ["exports", "ember-place-autocomplete/services/google-place-autocomplete/manager"], function (_exports, _manager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _manager.default;
    }
  });
});
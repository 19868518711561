define("hospodari/templates/components/offer/offer-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TnK9CuU7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"text-nowrap\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"created\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-circle text-muted\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" не обрано\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"accepted\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-check text-success\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" прийнято\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"rejected\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-circle text-muted\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" відхилено\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"canceled\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-ban text-warning\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" скасовано\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"status\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/offer/offer-status.hbs"
    }
  });

  _exports.default = _default;
});
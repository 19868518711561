define("hospodari/components/business/business-service-create-form", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "hospodari/config/environment"], function (_exports, _objectSpread2, _toConsumableArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var serviceTypes = (0, _toConsumableArray2.default)(_environment.default.service.types);

  var _default = Ember.Component.extend({
    serviceTypes: serviceTypes,
    // Services
    store: Ember.inject.service(),
    // Attributes
    item: null,
    // changeset
    error: null,
    services: null,
    loading: false,
    entity: null,
    step: 0,
    // default event handlers
    onDirtyChanged: function onDirtyChanged() {},
    onEntityServiceCreated: function onEntityServiceCreated() {},
    // Actions
    actions: {
      save: function save(entityServiceData) {
        var _this = this;

        return entityServiceData.validate().then(function () {
          if (entityServiceData.get('isValid')) {
            entityServiceData.save();
            var itemData = entityServiceData.get('data');
            var store = _this.store,
                entity = _this.entity;

            _this.set('error', null);

            var entityService = store.createRecord('entityservice', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, itemData), {}, {
              entity: entity
            }));
            return entityService.save().then(function (entityService) {
              _this.onDirtyChanged(false);

              _this.onEntityServiceCreated(entityService);
            }).catch(function (data) {
              var error = data.errors[0];
              var message = JSON.parse(error.detail).error.message;

              if (message === 'Entity already has relationship with this service') {
                _this.set('error', 'Ця категорія вже призначена.');
              } else if (message === 'This user do not have right to assign services') {
                _this.set('error', 'Ви не можете призначати категорії.');
              } else {
                _this.set('error', 'Помилка. Будь ласка, зверніться до адміністрації.');
              }
            });
          }
        });
      },
      handleCityDetailsChange: function handleCityDetailsChange(details) {
        this.get('item').setProperties(details ? Ember.getProperties(details, 'address_area_level_1', 'address_area_level_2', 'address_postal_code') : {
          address_area_level_1: null,
          address_area_level_2: null,
          address_postal_code: null
        });
      },
      handleServiceChange: function handleServiceChange(service) {
        this.setProperties({
          'item.service': service,
          // default service_type for entityservice is `job`
          'item.service_type': service.service_type || 'job',
          step: 1,
          error: null
        });
        this.onDirtyChanged(true);
      },
      setStep: function setStep(step) {
        this.set('step', step);
        this.set('error', null);
        this.onDirtyChanged(step > 0);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/utils/get-display-icon-class", ["exports", "livarava-framework/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getDisplayIconClass;

  function getDisplayIconClass(display) {
    var options = _environment.default.displayOptions;
    var out = 'fa-question';
    options.forEach(function (option) {
      if (option.value === display) {
        out = option.icon;
      }
    });
    return out;
  }
});
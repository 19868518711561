define("hospodari/components/business/business-invoices-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      getOrderId: function getOrderId(text) {
        var results = String(text).match(/#(\d+)/);
        return results && results[1] || null;
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/controllers/my/profile/index", ["exports", "hospodari/validations/user-validations"], function (_exports, _userValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    userValidations: _userValidations.default
  });

  _exports.default = _default;
});
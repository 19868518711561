define("hospodari/components/common/conditions-date-checker", ["exports", "moment", "hospodari/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    slug: '/conditions/',
    page: null,
    isOutdated: Ember.computed('page.created', 'me.{signed,user.hospodari_conditions_accepted}', function () {
      // ToDo: remove line below once implemented on backend
      // if (config.environment == 'local') return false;
      // skip for anonymous user
      if (!this.get('me.signed')) return false;
      var conditionsUpdated = this.get('page.created');
      var lastAccepted = this.get('me.user.hospodari_conditions_accepted');
      return conditionsUpdated && conditionsUpdated.isValid() && (!(lastAccepted && lastAccepted.isValid()) || (0, _moment.default)(lastAccepted).isBefore(conditionsUpdated));
    }),
    check: function check() {
      var _this = this;

      var store = this.get('store');
      var siteId = _environment.default.api.site;
      return store.query('page', {
        'filter[site]': siteId,
        'filter[path]': this.slug,
        per_page: 1,
        page: 1
      }).then(function (pages) {
        var page = pages.get('firstObject');
        if (!page) return null;
        return store.findRecord('page', page.id);
      }).then(function (page) {
        !_this.isDestroyed && _this.set('page', page);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.check();
    },
    actions: {
      update: function update() {
        var user = this.get('me.user');
        user.set('hospodari_conditions_accepted', (0, _moment.default)());
        return user.save();
      }
    }
  });

  _exports.default = _default;
});
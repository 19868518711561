define("hospodari/templates/components/building/building-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8zwKHpal",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card business-item p-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"business-aside col-12 col-md-3\"],[8],[0,\"\\n      \"],[1,[28,\"building/building-navigation\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n      \"],[1,[28,\"building/building-order-list\",null,[[\"item\",\"orders\",\"customer\",\"project\",\"product\",\"listType\"],[[24,[\"item\"]],[24,[\"orders\"]],[24,[\"customer\"]],[24,[\"project\"]],[24,[\"product\"]],\"table\"]]],false],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/building/building-orders.hbs"
    }
  });

  _exports.default = _default;
});
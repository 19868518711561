define("hospodari/components/order/order-item-building", ["exports", "hospodari/utils/get-error-message", "hospodari/validations/review-validations"], function (_exports, _getErrorMessage, _reviewValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reviewValidations: _reviewValidations.default,
    // Services
    me: Ember.inject.service(),
    store: Ember.inject.service(),
    // data
    isFinishing: false,
    isFinishShown: false,
    review: null,
    // Permissions
    canEdit: Ember.computed('order.{author.id,status}', 'me.id', function () {
      var status = Ember.get(this, 'order.status');
      var authorId = parseInt(Ember.get(this, 'order.author.id'));
      var meId = parseInt(Ember.get(this, 'me.id'));
      var isStatusOk = status === 'draft';
      var isAuthorOk = authorId === meId;
      return isStatusOk && isAuthorOk;
    }),
    canEditFiles: Ember.computed('order.author.id', 'me.id', function () {
      return this.get('order.author.id') == this.get('me.id');
    }),
    canCancel: Ember.computed('order.{author.id,status}', 'me.id', function () {
      return this.get('order.author.id') == this.get('me.id') && ['draft', 'opened'].includes(this.get('order.status'));
    }),
    canAccept: Ember.computed('order.{author.id,status}', 'me.id', 'offers', function () {
      var status = Ember.get(this, 'order.status');
      var authorId = parseInt(Ember.get(this, 'order.author.id'));
      var meId = parseInt(Ember.get(this, 'me.id'));
      var offers = Ember.get(this, 'offers');
      var isStatusOk = status === 'opened';
      var isAuthorOk = authorId === meId;
      var isOffersOk = offers.length > 0;
      return isStatusOk && isAuthorOk && isOffersOk;
    }),
    canFinish: Ember.computed('order.{author.id,status}', 'me.id', 'offers', function () {
      var status = Ember.get(this, 'order.status');
      var authorId = parseInt(Ember.get(this, 'order.author.id'));
      var meId = parseInt(Ember.get(this, 'me.id'));
      var offers = Ember.get(this, 'offers');
      var isStatusOk = status === 'accepted';
      var isAuthorOk = authorId === meId;
      var isOffersOk = offers.length > 0;
      return isStatusOk && isAuthorOk && isOffersOk;
    }),
    isFinished: Ember.computed('order.status', 'offers', function () {
      var status = Ember.get(this, 'order.status');
      var offers = Ember.get(this, 'offers');
      var isStatusOk = status === 'finished';
      var isOffersOk = offers.length > 0;
      return isStatusOk && isOffersOk;
    }),
    acceptedOffer: Ember.computed('offers.@each.status', function () {
      return (this.get('offers') || []).findBy('status', 'accepted');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('review', this.get('store').createRecord('review', {
        order: this.get('order'),
        entity: this.get('acceptedOffer.entity'),
        author: this.get('me.user')
      }));
    },
    // Actions
    actions: {
      finish: function finish(review) {
        var _this = this;

        if (!this.get('canFinish')) return;
        var order = this.order,
            project = this.project,
            product = this.product;
        this.setProperties({
          isFinishing: true,
          isFinishShown: false
        });
        order.set('status', 'finished');
        return Promise.resolve().then(function () {
          var action = _this.store.createRecord('action', {
            text: 'Замовник завершив угоду. Оцінка:\n ' + review.get('rating'),
            action_type: 'system',
            action_direction: 'in',
            author: _this.get('me.user'),
            project: project,
            product: product,
            order: order
          });

          return action.save();
        }).then(function () {
          order.set('status', 'finished');
          return order.save();
        }).catch(function (error) {
          _this.set('error', (0, _getErrorMessage.default)(error));
        }).finally(function () {
          return _this.set('isFinishing', false);
        });
      },
      cancel: function cancel(customerResponseText) {
        var _this2 = this;

        if (!this.get('canCancel')) return;
        var order = this.order,
            project = this.project,
            product = this.product;
        this.setProperties({
          isCanceling: true,
          isCancelShown: false
        });
        return Promise.resolve().then(function () {
          var action = _this2.store.createRecord('action', {
            text: 'Замовник скасував угоду. Причина:\n ' + customerResponseText,
            action_type: 'system',
            action_direction: 'in',
            manager: _this2.get('me.user'),
            customer: order.get('author'),
            author: _this2.get('me.user'),
            project: project,
            product: product,
            order: order
          });

          return action.save();
        }).then(function () {
          order.set('status', 'canceled');
          return order.save();
        }).catch(function (error) {
          _this2.set('error', (0, _getErrorMessage.default)(error));
        }).finally(function () {
          return _this2.set('isCanceling', false);
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/orders/apply", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.order.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var order = store.findRecord('order', params.id);
      var entity = store.findRecord('entity', params.business_id);
      var customer = store.findRecord('neuron', params.id);
      var project = store.findRecord('project', _environment.default.api.project);
      var product = store.findRecord('product', _environment.default.api.product);
      return Ember.RSVP.hash({
        order: order,
        entity: entity,
        customer: customer,
        project: project,
        product: product
      });
    },
    afterModel: function afterModel(model, transition) {
      var order = model.order;
      var entity = model.entity;
      Ember.set(this, 'titleToken', order.title);

      if (entity.is_active === false) {
        this.transitionTo('orders.activate', order.id, entity.id);
      }
    }
  });

  _exports.default = _default;
});
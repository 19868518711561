define("hospodari/templates/components/phone/manager-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CdisNtiY",
    "block": "{\"symbols\":[\"phone\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"manager\",\"id\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"manager\",\"image_url\"]]]]],[11,\"class\",[22,\"imageClass\"]],[11,\"alt\",[29,[[24,[\"manager\",\"fullname\"]]]]],[11,\"style\",[22,\"imageStyle\"]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"media-body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"h5 mt-0\"],[8],[0,\"\\n      \"],[1,[24,[\"manager\",\"fullname\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[14,2],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"phones\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"contactsClass\"],\" small\"]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"            \"],[7,\"i\",true],[11,\"class\",[29,[[23,1,[\"icon\"]]]]],[10,\"title\",\"Телефон\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"a\",true],[11,\"href\",[23,1,[\"link\"]]],[8],[0,\"\\n            \"],[1,[23,1,[\"label\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"contactsClass\"],\" small\"]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-envelope-o fa-gray\"],[10,\"title\",\"Електронна пошта\"],[8],[9],[0,\"\\n        \"],[7,\"a\",true],[10,\"href\",\"mailto:sales@hospodari.com\"],[8],[0,\"\\n          sales@hospodari.com\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/phone/manager-contacts.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/offer/offer-item-brief", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ndw0DOoH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"mb-3\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"caption mb-2\"],[8],[0,\"\\n        Виконавець\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mb-3\"],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[1,[24,[\"offer\",\"entity\",\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[1,[22,\"business/business-rate\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"mb-3\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"caption mb-2\"],[8],[0,\"\\n        Сума\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        ₴\"],[1,[28,\"number-format\",[[24,[\"offer\",\"amount\"]],\"0.00\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/offer/offer-item-brief.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/controllers/my/business/new-service", ["exports", "hospodari/validations/entityservice-validations"], function (_exports, _entityserviceValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    entityServiceValidations: _entityserviceValidations.default,
    hasChanges: false
  });

  _exports.default = _default;
});
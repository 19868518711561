define("hospodari/templates/my/buildings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FGfvlj99",
    "block": "{\"symbols\":[],\"statements\":[[4,\"common/common-header\",null,[[\"search\",\"yield\"],[false,true]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"mt-5\"],[8],[0,\"Моя нерухомість\"],[9],[0,\"\\n  \"],[7,\"nav\",true],[10,\"aria-label\",\"breadcrumb\"],[8],[0,\"\\n    \"],[7,\"ol\",true],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item\"],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"index\"]],{\"statements\":[[0,\"Головна\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item\"],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"my.index\"]],{\"statements\":[[0,\"Мій профіль\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item active\"],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"my.buildings.index\"]],{\"statements\":[[0,\"Моя нерухомість\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\n    \"],[1,[28,\"building/building-list\",null,[[\"items\"],[[24,[\"model\",\"items\"]]]]],false],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"common/common-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/my/buildings/index.hbs"
    }
  });

  _exports.default = _default;
});
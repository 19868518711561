define("hospodari/components/review/review-form", ["exports", "hospodari/utils/get-error-message"], function (_exports, _getErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    item: null,
    onSave: function onSave() {},
    actions: {
      save: function save(item) {
        var _this = this;

        return item.validate().then(function () {
          if (item.get('isValid')) {
            return item.save().then(function (item) {
              return _this.onSave(item);
            }).catch(function (data) {
              _this.set('error', (0, _getErrorMessage.default)(data));
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});
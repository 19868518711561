define("hospodari/routes/my/orders/edit", ["exports", "hospodari/config/environment", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Model
    model: function model(params) {
      var me = Ember.get(this, 'me');
      var store = Ember.get(this, 'store');
      var item = store.findRecord('order', params.id);
      var properties = store.query('property', {
        'filter[site]': _environment.default.api.site,
        'filter[user]': parseInt(me.id)
      });
      var services = store.query('service', {
        page: 1,
        per_page: 100,
        'filter[display]': 'public',
        'filter[service_type]': 'job',
        sort: '-title'
      });
      return Ember.RSVP.hash({
        item: item,
        properties: properties,
        services: services
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // Закрити можливість переглядати деталі угоди на сайті якщо юзер не є автором угоди
      if (model.item.get('author.id') == this.get('me.id')) {
        return true;
      }

      return model.item.get('property').then(function (property) {
        if (!property) {
          return false;
        }

        return _this.store.query('propertyuser', {
          'filter[property]': property && property.id,
          'filter[user]': _this.get('me.id')
        });
      }).then(function (propertyusers) {
        if (!propertyusers.length) throw new Error('Not allowed to see order details');
      });
    }
  });

  _exports.default = _default;
});
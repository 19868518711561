define("hospodari/routes/my/orders/index", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Мої угоди',
    // Model
    model: function model() {
      var me = Ember.get(this, 'me');
      var store = Ember.get(this, 'store');
      var authorId = parseInt(me.id);
      var projectId = _environment.default.api.project;
      var productId = _environment.default.api.product;
      return Ember.RSVP.hash({
        items: store.query('order', {
          'filter[author]': authorId,
          'filter[project]': projectId,
          'filter[product]': productId,
          page: 1,
          per_page: 100
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/business/business-members-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wCw/Q3tf",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[28,\"business/business-members-list-item\",null,[[\"class\",\"item\",\"onUserChanged\",\"onUserDeleted\"],[\"mb-3\",[23,1,[]],[24,[\"onUserChanged\"]],[24,[\"onUserDeleted\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-members-list.hbs"
    }
  });

  _exports.default = _default;
});
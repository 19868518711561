define("hospodari/components/business/business-order-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    listType: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('listType', this.get('media.isMobile') ? 'tiles' : 'table');
      this.get('store').query('entityservice', {
        'filter[entity]': this.get('item.id')
      }).then(function (entityservices) {
        return Promise.all(entityservices.map(function (es) {
          return es.get('service');
        }));
      }).then(function (services) {
        return _this.set('services', services);
      });
    },
    actions: {
      handleServiceFilterChange: function handleServiceFilterChange(service) {
        this.set('service', service && service.id);
      }
    }
  });

  _exports.default = _default;
});
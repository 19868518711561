define("hospodari/routes/blog/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var _this = this;

      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        item: store.find('post', params.id).then(function (item) {
          Ember.set(_this, 'titleToken', Ember.get(item, 'title'));
          return item;
        }).catch(function () {
          return null;
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
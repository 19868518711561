define("hospodari/routes/my/buildings/order", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.item.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var propertyId = parseInt(params.id);
      var orderId = parseInt(params.oid);
      var property = store.findRecord('property', propertyId);
      var order = store.findRecord('order', orderId);
      var offers = store.query('offer', {
        'filter[order]': orderId
      });
      return Ember.RSVP.hash({
        property: property,
        order: order,
        offers: offers
      });
    }
  });

  _exports.default = _default;
});
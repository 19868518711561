define("hospodari/templates/components/input-address-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+b7knmbn",
    "block": "{\"symbols\":[\"dd\",\"menu\",\"type\",\"&default\"],\"statements\":[[5,\"bs-dropdown\",[[12,\"class\",\"input-group-prepend\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"button\"]],[],[[\"@type\",\"@outline\"],[\"secondary\",true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Тип\\n      \"],[5,\"bs-tooltip\",[],[[\"@placement\"],[\"top\"]],{\"statements\":[[0,\"\\n        Виберіть тип вулиці: бульвар, проспект, провулок...\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"span\",true],[10,\"class\",\"caret\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"menu\"]],[[12,\"class\",\"small\"]],[[\"@align\"],[\"right\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"types\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"dropdown-item text-capitalize\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"select\",[23,3,[]]]],[8],[0,\"\\n        \"],[1,[23,3,[\"full\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[14,4]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/input-address-type.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/order/order-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    store: Ember.inject.service(),
    // Permissions
    canEdit: Ember.computed('order.author.id', 'me.id', function () {
      var authorId = parseInt(Ember.get(this, 'order.author.id'));
      var meId = parseInt(Ember.get(this, 'me.id'));
      return authorId === meId;
    }),
    canActivate: Ember.computed('entity.is_active', 'canEdit', function () {
      var canEdit = Ember.get(this, 'canEdit');
      var isActive = Ember.get(this, 'entity.is_active');
      if (canEdit) return false;
      return !isActive;
    }),
    canApply: Ember.computed('order.status', 'entity.is_active', 'offers', function () {
      var status = Ember.get(this, 'order.status');
      var isActive = Ember.get(this, 'entity.is_active');
      var entityId = parseInt(Ember.get(this, 'entity.id'));
      var offers = Ember.get(this, 'offers');
      offers.forEach(function (offer) {
        var offerEntityId = parseInt(Ember.get(offer, 'entity.id'));
        if (offerEntityId === entityId) return false;
      });
      return status === 'draft' && isActive;
    }),
    canViewDetails: Ember.computed('order.status', 'entity.id', 'invoices', 'offers', function () {
      var entityId = Ember.get(this, 'entity.id');
      var invoices = Ember.get(this, 'invoices');
      if (!invoices) return false;
      invoices.forEach(function (invoice) {
        var customerId = Ember.get(invoice, 'customer.id');
        var status = Ember.get(invoice, 'status');
        if (customerId === entityId && status === 'payed') return true;
      });
      return false;
    }),
    canViewOffers: Ember.computed('canEdit', 'canViewDetails', function () {
      var canEdit = Ember.get(this, 'canEdit');
      var canViewDetails = Ember.get(this, 'canViewDetails');
      return canEdit || canViewDetails;
    }),
    canOffer: Ember.computed.reads('canView'),
    // Actions
    actions: {
      accept: function accept() {
        var store = Ember.get(this, 'store');
        var created = moment();
        var order = Ember.get(this, 'order');
        var entity = Ember.get(this, 'entity');
        var customer = Ember.get(this, 'customer');
        var project = Ember.get(this, 'project');
        var product = Ember.get(this, 'product');
        var amount, description;
        amount = order.apply_amount;
        description = "\u0406\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u0456 \u043F\u043E\u0441\u043B\u0443\u0433\u0438 \u0437\u0430 \u043E\u0431\u0440\u043E\u0431\u043A\u0443 \u0443\u0433\u043E\u0434\u0438 #".concat(order.id);
        var invoice = store.createRecord('invoice', {
          created: created,
          seller: 'Hospodari Ukraine',
          fullname: entity.title,
          address: entity.address,
          email: entity.email,
          phone: entity.phone,
          amount: amount,
          currency: 'UAH',
          lines: Ember.A([Ember.Object.create({
            description: description,
            price: amount
          })]),
          customer: customer,
          project: project,
          product: product,
          order: order
        });
        invoice.save().then(function () {
          var paymentUrl = invoice.paymentUrl;
          var returnUrl = "http://local.hospodari.com/orders/".concat(order.id, "/payed");
          paymentUrl += "&return=" + encodeURIComponent(returnUrl);
          window.open(paymentUrl, '_blank'); // get(this, 'router').transitionTo('my.business.invoices', entity.id);
        });
      },
      reject: function reject() {
        var _this = this;

        var store = Ember.get(this, 'store');
        var created = moment();
        var order = Ember.get(this, 'order');
        var entity = Ember.get(this, 'entity');
        var project = Ember.get(this, 'project');
        var product = Ember.get(this, 'product');
        var amount, description;
        amount = order.apply_amount;
        description = "\u0406\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u0456 \u043F\u043E\u0441\u043B\u0443\u0433\u0438 \u0437\u0430 \u043E\u0431\u0440\u043E\u0431\u043A\u0443 \u0443\u0433\u043E\u0434\u0438 #".concat(order.id);
        var offer = store.createRecord('offer', {
          created: created,
          amount: amount,
          currency: 'UAH',
          project: project,
          product: product,
          order: order,
          entity: entity,
          status: 'rejected'
        });
        offer.save().then(function () {
          Ember.get(_this, 'router').transitionTo('my.business.order', entity.id, order.id);
        });
      },
      offer: function offer() {
        var order = Ember.get(this, 'order');
        var entity = Ember.get(this, 'entity');
        Ember.get(this, 'router').transitionTo('my.business.offer', entity.id, order.id);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    titleFirstLetter: Ember.computed('title', function () {
      var title = Ember.get(this, 'title');
      return title.charAt(0).capitalize();
    }),
    type: 'project',
    header: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    preorders_notifications: _emberData.default.attr(),
    sections: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    // Visibility
    display: _emberData.default.attr('string'),
    featured: _emberData.default.attr('boolean'),
    // Stats
    views: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("hospodari/templates/components/building/building-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sqdNcM1U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card business-invoices p-5\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"business-aside col-12 col-md-3\"],[8],[0,\"\\n\\n        \"],[1,[28,\"building/building-navigation\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"building/building-invoices-list\",null,[[\"items\"],[[24,[\"items\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        У вас ще немає сформованих рахунків.\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/building/building-invoices.hbs"
    }
  });

  _exports.default = _default;
});
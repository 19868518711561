define("hospodari/routes/my/business/services", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        item: store.findRecord('entity', params.id),
        items: store.query('entityservice', {
          'filter[entity]': params.id,
          page: 1,
          per_page: 100
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/my/business/order", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _objectSpread2, _moment, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Service
    store: Ember.inject.service(),
    // Title
    titleToken: Ember.computed.reads('model.order.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var entityId = parseInt(params.id);
      var orderId = parseInt(params.oid);
      var projectId = parseInt(_environment.default.api.project);
      var productId = parseInt(_environment.default.api.product);
      var entity = store.findRecord('entity', entityId);
      var order = store.findRecord('order', orderId);
      var customer = store.findRecord('neuron', entityId);
      var project = store.findRecord('project', projectId);
      var product = store.findRecord('product', productId);
      var transactions = store.query('transaction', {
        'filter[order]': orderId,
        'filter[customer]': entityId
      });
      var offers = store.query('offer', {
        'filter[order]': orderId
      });
      return Ember.RSVP.hash({
        order: order,
        entity: entity,
        customer: customer,
        project: project,
        product: product,
        transactions: transactions,
        offers: offers
      }).then(function (results) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, results), {}, {
          entityservices: store.query('entityservice', {
            'filter[entity]': entityId,
            'filter[service]': results.order.get('service.id')
          })
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      var order = model.order;
      Ember.set(this, 'titleToken', order.title);
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      },
      didTransition: function didTransition() {
        var _this = this;

        // При перегляді заявки бізнесом створювати action (від юзера, що відкрив) заявки
        var store = this.get('store');
        var user = this.get('me.user');
        var order = this.get('controller.model.order');
        var entity = this.get('controller.model.entity');
        var project = this.get('controller.model.project');
        var product = this.get('controller.model.product');
        var url = window.location.href;
        store.query('action', {
          'filter[author]': user.get('id'),
          'filter[order]': order.get('id')
        }).then(function (orders) {
          if (orders && orders.length > 0) {
            return;
          }

          var scheduled = (0, _moment.default)().add('day', 1);

          var action = _this.store.createRecord('action', {
            scheduled: scheduled,
            manager: user,
            action_type: 'system',
            action_direction: 'in',
            author: user,
            order: order,
            project: project,
            product: product,
            text: "\u041A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447 #".concat(user.get('id'), " ").concat(user.get('fullname'), " \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0432 \u0437\u0430\u044F\u0432\u043A\u0443 #").concat(order.get('id'), " \u0432\u0456\u0434 \u0431\u0456\u0437\u043D\u0435\u0441\u0443 #").concat(entity.get('id'), " \"").concat(entity.get('title'), "\" \u0437\u0430 \u043F\u043E\u0441\u0438\u043B\u0430\u043D\u043D\u044F\u043C \"").concat(url, "\"")
          }); // console.log(action.toJSON());


          action.save();
        });
        return true; // Bubble the didTransition event
      }
    }
  });

  _exports.default = _default;
});
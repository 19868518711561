define("hospodari/routes/services/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Всі послуги',
    redirect: function redirect(model, transition) {
      if (Ember.get(transition, 'from.name') !== 'services.index' && transition.to.queryParams.step > 1) {
        transition.abort();
        var url = this.router.urlFor('services.index', {
          queryParams: {}
        });
        window.location.href = url;
      }
    },
    // Model
    model: function model() {},
    actions: {},
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('step', 1);
      }
    }
  });

  _exports.default = _default;
});
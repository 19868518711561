define("hospodari/components/common/common-pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Properties:
    current: null,
    count: Ember.computed.reads('items.meta.total_pages'),
    prev: Ember.computed('current', function () {
      return Math.max(parseInt(Ember.get(this, 'current')) - 1, 1);
    }),
    next: Ember.computed('current', 'count', function () {
      return parseInt(Ember.get(this, 'current')) + 1;
    }),
    last: Ember.computed('count', function () {
      return parseInt(Ember.get(this, 'count'));
    }),
    // Flags
    showPagination: Ember.computed('count', function () {
      return parseInt(Ember.get(this, 'count')) > 1;
    }),
    isFirstDisabled: Ember.computed('current', function () {
      return parseInt(Ember.get(this, 'current')) === 1;
    }),
    isPreviousDisabled: Ember.computed('current', function () {
      return parseInt(Ember.get(this, 'current')) === 1;
    }),
    isNextDisabled: Ember.computed('current', 'count', function () {
      return parseInt(Ember.get(this, 'current')) >= parseInt(Ember.get(this, 'count'));
    }),
    isLastDisabled: Ember.computed('current', function () {
      return parseInt(Ember.get(this, 'current')) >= parseInt(Ember.get(this, 'count'));
    }),
    // Klass
    klass: Ember.computed('align', function () {
      var align = Ember.get(this, 'align');

      if (align === 'center') {
        return 'justify-content-center';
      } else if (align === 'right') {
        return 'justify-content-end';
      } else if (align === 'left') {
        return 'justify-content-begin';
      }
    }),
    // Actions
    actions: {
      setPage: function setPage(page) {
        Ember.set(this, 'current', page);
      }
    }
  });

  _exports.default = _default;
});
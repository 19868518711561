define("hospodari/templates/components/input-property-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aXANSE6I",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"select\",true],[11,\"class\",[29,[[22,\"klass\"]]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"optionChanged\"],[[\"value\"],[\"target.value\"]]]],[8],[0,\"\\n  \"],[7,\"option\",true],[8],[1,[22,\"placeholder\"],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[11,\"value\",[23,1,[\"property\",\"id\"]]],[11,\"selected\",[28,\"eq\",[[24,[\"value\",\"id\"]],[23,1,[\"property\",\"id\"]]],null]],[8],[1,[23,1,[\"property\",\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/input-property-select.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/signup-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getError(errors, field) {
    var error = errors.find(function (_ref) {
      var attribute = _ref.attribute;
      return attribute === field;
    });
    return error ? error.message.join() : null;
  }

  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    // Params
    type: null,
    error: null,
    // Attributes
    firstname: null,
    lastname: null,
    phone: null,
    email: null,
    password: null,
    // Flags
    isLoading: false,
    wasValidated: false,
    isTermsAccepted: true,
    // Computed
    firstnameError: Ember.computed('errors.[]', function () {
      var errors = Ember.get(this, 'errors') || [];
      return getError(errors, 'firstname');
    }),
    firstnameClass: Ember.computed('wasValidated', 'firstnameError', function () {
      if (!Ember.get(this, 'wasValidated')) return 'form-control';
      return Ember.get(this, 'firstnameError') ? 'form-control is-invalid' : 'form-control is-valid';
    }),
    lastnameError: Ember.computed('errors.[]', function () {
      var errors = Ember.get(this, 'errors') || [];
      return getError(errors, 'lastname');
    }),
    lastnameClass: Ember.computed('wasValidated', 'lastnameError', function () {
      if (!Ember.get(this, 'wasValidated')) return 'form-control';
      return Ember.get(this, 'lastnameError') ? 'form-control is-invalid' : 'form-control is-valid';
    }),
    phoneError: Ember.computed('errors.[]', function () {
      var errors = Ember.get(this, 'errors') || [];
      return getError(errors, 'phone');
    }),
    phoneClass: Ember.computed('wasValidated', 'phoneError', function () {
      if (!Ember.get(this, 'wasValidated')) return 'form-control';
      return Ember.get(this, 'phoneError') ? 'form-control is-invalid' : 'form-control is-valid';
    }),
    actions: {
      signup: function signup(item) {
        var _this = this;

        return item.validate().then(function () {
          if (item.get("isValid")) {
            // Services
            var me = Ember.get(_this, "me");
            var session = Ember.get(_this, "session");
            var router = Ember.get(_this, "router"); // Attributes

            var firstname = Ember.get(_this, "item.firstname");
            var lastname = Ember.get(_this, "item.lastname");
            var phone = Ember.get(_this, "item.phone");
            var email = Ember.get(_this, "item.email");
            var password = Ember.get(_this, "item.password"); // Result

            var result = me.signUp(firstname, lastname, phone, email, password);
            Ember.set(_this, "error", null);
            Ember.set(_this, "isLoading", true);
            result.then(function (data) {
              Ember.set(_this, "isLoading", false);
              var error = data.error;

              if (error) {
                Ember.set(_this, "error", error);
              } else {
                Ember.set(_this, "isLoading", true);
                Ember.set(_this, "error", null);
                session.authenticate("authenticator:custom", email, password).then(function (response) {
                  Ember.set(_this, "isLoading", false);
                  var type = Ember.get(_this, "type");

                  if (type === "business") {
                    router.transitionTo("signup-business-owner-complete");
                  } else {
                    router.transitionTo("signup-building-owner-complete");
                  }
                }).catch(function (response) {
                  Ember.set(_this, "isLoading", false);
                  Ember.set(_this, "error", response.error || "Помилка авторизації. Будь ласка, зверніться до admin@livarava.com");
                });
              }
            }).catch(function (error) {
              Ember.set(_this, "isLoading", false);
              Ember.set(_this, "error", "Помилка. Будь ласка, зверніться до admin@livarava.com");
            });
          }
        });
      },
      onEmailKeyUp: function onEmailKeyUp(a, b, c) {
        var email = Ember.get(this, 'email');
        Ember.set(this, 'email', email.toLowerCase());
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/offer/edit-offer-form", ["exports", "hospodari/utils/get-error-message"], function (_exports, _getErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    onSave: function onSave() {},
    // data
    isSaving: false,
    isCancelling: false,
    // Actions
    actions: {
      changeAmount: function changeAmount(customerResponseText) {
        var _this = this;

        var offer = this.offer,
            project = this.project,
            product = this.product,
            entity = this.entity,
            order = this.order;
        this.setProperties({
          isSaving: true,
          isEditAmountShown: false
        });
        return offer.save().then(function () {
          var action = _this.store.createRecord('action', {
            text: 'Виконавець змінив суму офера. Причина:\n ' + customerResponseText,
            action_type: 'system',
            action_direction: 'in',
            manager: _this.get('me.user'),
            customer: order.get('author'),
            author: _this.get('me.user'),
            project: project,
            product: product,
            entity: entity,
            order: order
          });

          return action.save();
        }).catch(function (error) {
          _this.set('error', (0, _getErrorMessage.default)(error));
        }).then(function () {
          return _this.onSave();
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      },
      cancelOffer: function cancelOffer(customerResponseText) {
        var _this2 = this;

        var offer = this.offer,
            project = this.project,
            product = this.product,
            entity = this.entity,
            order = this.order;
        this.setProperties({
          isCancelling: true,
          isСancelShown: false
        });
        offer.set('status', 'canceled');
        return offer.save().then(function () {
          var action = _this2.store.createRecord('action', {
            text: 'Виконавець скасував офер. Причина:\n ' + customerResponseText,
            action_type: 'system',
            action_direction: 'in',
            manager: _this2.get('me.user'),
            customer: order.get('author'),
            author: _this2.get('me.user'),
            project: project,
            product: product,
            entity: entity,
            order: order
          });

          return action.save();
        }).catch(function (error) {
          _this2.set('error', (0, _getErrorMessage.default)(error));
        }).then(function () {
          return _this2.onSave();
        }).finally(function () {
          return _this2.set('isCancelling', false);
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/webinar-owners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+OQqeAzP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"common/common-header\",null,[[\"search\",\"menu\"],[false,false]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n  \"],[1,[22,\"webinar/webinar-item\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"common/common-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/webinar-owners.hbs"
    }
  });

  _exports.default = _default;
});
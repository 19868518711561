define("hospodari/routes/my/business/offer", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Service
    store: Ember.inject.service(),
    // Title
    titleToken: Ember.computed.reads('model.order.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var entityId = parseInt(params.id);
      var orderId = parseInt(params.oid);
      var projectId = _environment.default.api.project;
      var productId = _environment.default.api.product;
      var entity = store.findRecord('entity', entityId);
      var order = store.findRecord('order', orderId);
      var project = store.findRecord('project', projectId);
      var product = store.findRecord('product', productId);
      var item = store.createRecord('offer', {
        created: moment(),
        amount: 0
      });
      return Ember.RSVP.hash({
        item: item,
        order: order,
        entity: entity,
        project: project,
        product: product
      });
    },
    afterModel: function afterModel(model, transition) {
      var order = model.order;
      var entity = model.entity;
      var project = model.project;
      var product = model.product;
      Ember.set(this, 'titleToken', order.title);
      Ember.set(model, 'item.amount', order.amount);
      Ember.set(model, 'item.order', order);
      Ember.set(model, 'item.entity', entity);
      Ember.set(model, 'item.project', project);
      Ember.set(model, 'item.product', product);
    }
  });

  _exports.default = _default;
});
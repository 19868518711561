define("hospodari/routes/my/orders/item", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.item.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var orderId = parseInt(params.id);
      var projectId = parseInt(_environment.default.api.project);
      var productId = parseInt(_environment.default.api.product);
      var order = store.findRecord('order', orderId);
      var offers = store.query('offer', {
        'filter[order]': orderId
      });
      var reviews = store.query('review', {
        'filter[order]': orderId
      });
      var project = store.findRecord('project', projectId);
      var product = store.findRecord('product', productId, reviews);
      return Ember.RSVP.hash({
        order: order,
        offers: offers,
        reviews: reviews,
        project: project,
        product: product
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // Закрити можливість переглядати деталі угоди на сайті якщо юзер не є автором угоди
      if (model.order.get('author.id') == this.get('me.id')) {
        return true;
      }

      return model.order.get('property').then(function (property) {
        if (!property) {
          return false;
        }

        return _this.store.query('propertyuser', {
          'filter[property]': property && property.id,
          'filter[user]': _this.get('me.id')
        });
      }).then(function (propertyusers) {
        if (!propertyusers.length) throw new Error('Not allowed to see order details');
      });
    }
  });

  _exports.default = _default;
});
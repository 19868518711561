define("hospodari/utils/get-error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getErrorMessage;

  function getErrorMessage() {
    var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var errors = {};
    errors['Title is required'] = "Назва є обов'язковим полем.";

    if (String(error).startsWith('Duplicate title')) {
      return 'Така назва вже використовується! Змініть назву.';
    }

    if (String(error).startsWith('User already exists')) {
      return 'Користувач з таким номером телефону чи email вже існує.';
    }

    return errors[error] || 'Помилка! Будь ласка, зверніться до admin@livarava.com';
  }
});
define("hospodari/components/service/service-business-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cities: Ember.computed('item.entityservices.@each.city', function () {
      var entityservices = this.get('item.entityservices');

      if (!entityservices) {
        return null;
      }

      return entityservices.mapBy('city').filter(function (c) {
        return !!c;
      });
    })
  });

  _exports.default = _default;
});
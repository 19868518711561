define("hospodari/services/google-place-autocomplete", ["exports", "ember-place-autocomplete/services/google-place-autocomplete"], function (_exports, _googlePlaceAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googlePlaceAutocomplete.default;
    }
  });
});
define("hospodari/services/fb", ["exports", "ember-cli-facebook-js-sdk/services/fb"], function (_exports, _fb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fb.default;
    }
  });
});
define("hospodari/models/site", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Type
    type: 'site',
    // Attributes
    title: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    feedback_email: _emberData.default.attr('string'),
    api_key: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    // Relationships
    author: (0, _relationships.belongsTo)('user'),
    // Meta
    meta_title: _emberData.default.attr('string'),
    meta_description: _emberData.default.attr('string'),
    meta_keywords: _emberData.default.attr('string'),
    meta_image: _emberData.default.belongsTo('neuron'),
    meta_image_url: _emberData.default.attr('string'),
    // Other
    rights: _emberData.default.attr('plain-object'),
    // Billing
    plan: _emberData.default.attr('string'),
    currency: _emberData.default.attr('string'),
    billing_fullname: _emberData.default.attr('string'),
    billing_address: _emberData.default.attr('string'),
    billing_email: _emberData.default.attr('string'),
    billing_phone: _emberData.default.attr('string'),
    balance: _emberData.default.attr('plain-object'),
    space_total: _emberData.default.attr('string'),
    space_used: _emberData.default.attr('string'),
    space_left: _emberData.default.attr('string'),
    space_calculating: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});
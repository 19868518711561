define("hospodari/routes/services/category", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Params
    queryParams: {
      city: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var _this = this;

      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        item: store.find('service', params.id).then(function (item) {
          Ember.set(_this, 'titleToken', Ember.get(item, 'title'));
          return item;
        }).catch(function () {
          return null;
        }),
        city: params.city,
        posts: store.query('post', {
          'filter[site]': _environment.default.api.site,
          lira: params.id,
          per_page: 6,
          page: 1
        }),
        propertyusers: this.get('me.id') ? store.query('propertyuser', {
          'filter[user]': this.get('me.id')
        }) : null
      });
    },
    resetController: function resetController(controller, isExiting
    /*transition*/
    ) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.setProperties({
          city: null
        });
      }
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
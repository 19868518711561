define("hospodari/routes/orders/activate", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.order.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var order = store.findRecord('order', params.id);
      var entity = store.findRecord('entity', params.business_id);
      return Ember.RSVP.hash({
        order: order,
        entity: entity
      });
    },
    afterModel: function afterModel(model, transition) {
      var order = model.order;
      var entity = model.entity;
      Ember.set(this, 'titleToken', order.title);

      if (entity.is_active === true) {
        this.transitionTo('orders.apply', order.id, entity.id);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/services/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1O+qzyEH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"common/common-header\",null,[[\"search\",\"yield\"],[false,true]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"mt-5\"],[8],[0,\"\\n    \"],[1,[24,[\"model\",\"item\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"nav\",true],[10,\"aria-label\",\"breadcrumb\"],[8],[0,\"\\n    \"],[7,\"ol\",true],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"index\"]],{\"statements\":[[0,\"          Головна\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item active\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"index\"]],{\"statements\":[[0,\"          Послуги\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"services\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container mt-5 mb-5\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title-block col-12 col-sm-12 col-md-12 mb-4\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"divider mb-3\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"mr-3\"],[8],[0,\"\\n          ГОСПОДАРІ\\n        \"],[9],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/img/homepage_divider.svg\"],[10,\"class\",\"\"],[10,\"alt\",\"Рисочка\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        Всі послуги\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[5,\"index/index-catalog\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"common/common-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/services/index.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/my/orders/new", ["exports", "hospodari/config/environment", "hospodari/mixins/authenticated-route-mixin", "moment"], function (_exports, _environment, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var confirmText = 'Ви впевнені, що хочете припинити додавання нової угоди?';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      property: {
        refreshModel: true
      },
      service: {
        refreshModel: true
      },
      entity: {
        refreshModel: true
      }
    },
    // Title
    titleToken: 'Нова угода',
    redirect: function redirect(model, transition) {
      if (transition.to.queryParams.step > 0 || transition.to.queryParams.subStep > 1) {
        transition.abort();
        var url = this.router.urlFor('my.orders.new', {
          queryParams: {}
        });
        window.location.href = url;
      }
    },
    // Model
    model: function model(params) {
      var me = Ember.get(this, 'me');
      var store = Ember.get(this, 'store');
      var firstname = Ember.get(this, 'me.firstname');
      var lastname = Ember.get(this, 'me.lastname');
      var phone = Ember.get(this, 'me.phone');
      var email = Ember.get(this, 'me.email');
      var userId = parseInt(me.id);
      var projectId = parseInt(_environment.default.api.project);
      var productId = parseInt(_environment.default.api.product);
      var project = store.findRecord('project', projectId);
      var product = store.findRecord('product', productId);
      var properties = store.query('propertyuser', {
        'filter[user]': userId
      });
      var services = store.query('service', {
        page: 1,
        per_page: 100,
        'filter[display]': 'public',
        'filter[service_type]': 'job',
        sort: '-title'
      });
      var propertyId = params && params.property;
      var serviceId = params && params.service;
      var entityId = params && params.entity;
      var promises = {
        property: null,
        propertyusers: null,
        service: null
      };

      if (propertyId) {
        promises.property = store.findRecord('property', propertyId).catch(function () {
          return null;
        });
        promises.propertyusers = properties;
      }

      if (serviceId) {
        promises.service = store.findRecord('service', serviceId).catch(function () {
          return null;
        });
      }

      if (entityId) {
        promises.entity = store.findRecord('entity', entityId).catch(function () {
          return null;
        });
      }

      return Ember.RSVP.hash(promises).then(function (result) {
        return result;
      }).catch(function () {}).then(function (_ref) {
        var property = _ref.property,
            propertyusers = _ref.propertyusers,
            service = _ref.service,
            entity = _ref.entity;
        var orderData = {
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          email: email,
          created: (0, _moment.default)(),
          apply_deadline: (0, _moment.default)(),
          final_deadline: (0, _moment.default)(),
          // amount: 300,
          service: service
        }; //check if user owns requested property

        if (property) {
          var isAuthor = property.get('author.id') == userId;
          var isMember = propertyusers.any(function (pu) {
            return pu.get('property.id') == propertyId;
          });

          if (isAuthor || isMember) {
            orderData.property = property;
            Object.assign(orderData, property.getProperties('address', 'address_area_level_1', 'address_area_level_2', 'address_postal_code', 'city', 'address_type', 'address_name', 'address_building', 'address_apartment'));
          }
        }

        var item = store.createRecord('order', orderData);
        return Ember.RSVP.hash({
          item: item,
          properties: properties,
          services: services,
          project: project,
          product: product,
          entity: entity
        });
      });
    },
    afterModel: function afterModel(model
    /*transition*/
    ) {
      // const propertyusers = model.propertyusers;
      // let properties = A();
      //
      // propertyusers.forEach((propertyuser) => {
      //     const property = get(propertyuser, 'property');
      //     properties.addObject(property);
      // });
      Ember.set(model, 'item.project', Ember.get(model, 'project'));
      Ember.set(model, 'item.product', Ember.get(model, 'product')); // set(model, 'properties', properties);
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.setProperties({
          property: null,
          service: null,
          entity: null,
          hasChanges: false,
          step: 0,
          subStep: 1
        });
      }
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      },
      handleAdded: function handleAdded(order) {
        this.transitionTo('my.orders.item', order.get('id'));
      },
      willTransition: function willTransition(transition) {
        if (this.controller.hasChanges && !confirm(confirmText)) {
          transition.abort();
        } else {
          // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/error/error-404", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    api: Ember.inject.service(),
    router: Ember.inject.service(),
    // Attributes
    version: Ember.computed.reads('api.version'),
    // Actions
    actions: {
      signout: function signout() {
        var me = Ember.get(this, 'me');
        var router = Ember.get(this, 'router');
        var res = me.signOut();
      },
      onServiceChange: function onServiceChange(q) {},
      onServiceSelect: function onServiceSelect(item) {
        var _Ember$get;

        if (!item) return;

        (_Ember$get = Ember.get(this, 'router')).transitionTo.apply(_Ember$get, (0, _toConsumableArray2.default)(item.get('parent.id') ? ['services.item', item.get('parent.id'), item.id] : ['services.category', item.id]));
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/index", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    title: 'Господарі',
    // Model
    model: function model() {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        services: store.query('service', {
          page: 1,
          per_page: 30,
          'filter[display]': 'public',
          'filter[featured]': true,
          sort: 'order'
        }),
        forwhom: store.findRecord('page', 149298),
        categories: store.findRecord('page', 149909),
        segments: store.findRecord('page', 150131),
        advantages: store.findRecord('page', 149917),
        faq: store.findRecord('page', 149869),
        testimonials: store.findRecord('page', 149906),
        ads: store.query('post', {
          site: _environment.default.api.site,
          per_page: 12,
          display: 'public',
          featured: true,
          lira: 167694
        })
      });
    }
  });

  _exports.default = _default;
});
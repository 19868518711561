define("hospodari/models/entityuser", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Attributes
    role: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Author
    author: _emberData.default.belongsTo('user'),
    entity: _emberData.default.belongsTo('entity'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});
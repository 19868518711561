define("hospodari/templates/blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nRUpHyjr",
    "block": "{\"symbols\":[\"floatingBlock\"],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"floating-block\",[],[[\"@buttonLabel\",\"@timeout\"],[[28,\"if\",[[24,[\"media\",\"isResponsive\"]],\"Замовити\",\"Заявка в 1 клік\"],null],\"0\"]],{\"statements\":[[0,\"\\n  \"],[5,\"order/quick-order\",[],[[\"@onClose\"],[[28,\"action\",[[23,0,[]],[23,1,[\"toggle\"]],false],null]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/blog.hbs"
    }
  });

  _exports.default = _default;
});
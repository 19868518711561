define("hospodari/components/building/building-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    listType: 'tiles'
  });

  _exports.default = _default;
});
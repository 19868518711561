define("hospodari/templates/components/input-with-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vf5dGzk5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-row mb-3\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"class\",\"type\",\"value\",\"placeholder\",\"input\"],[[24,[\"klass\"]],[24,[\"type\"]],[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"input\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"invalid-feedback\"],[8],[0,\"\\n    \"],[1,[22,\"error\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/input-with-validation.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/index/index-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TUIH64Sy",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[[12,\"class\",\"homepage-service-item d-flex align-items-center my-1\"]],[[\"@route\",\"@models\",\"@query\"],[[28,\"service-link\",[[24,[\"item\"]]],null],[28,\"service-link\",[[24,[\"item\"]],true],null],[28,\"hash\",null,[[\"city\"],[[24,[\"me\",\"city\"]]]]]]],{\"statements\":[[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[[24,[\"item\",\"icon_class\"]],\" service-ho icon mr-2 d-none d-md-inline-block\"]]],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"subtitle text\"],[8],[0,\"\\n    \"],[1,[24,[\"item\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/index/index-service.hbs"
    }
  });

  _exports.default = _default;
});
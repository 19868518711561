define("hospodari/components/offer/offer-form", ["exports", "hospodari/utils/get-error-message"], function (_exports, _getErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Actions
    actions: {
      save: function save() {
        var _this = this;

        var item = Ember.get(this, 'item');
        return item.save().then(function (item) {
          var entityId = parseInt(Ember.get(item, 'entity.id'));
          var orderId = parseInt(Ember.get(item, 'order.id'));
          Ember.get(_this, 'router').transitionTo('my.business.order', entityId, orderId);
        }).catch(function (data) {
          // const errors = data.errors[0].detail;
          // let error = JSON.parse(errors).error.message;
          // console.log(error);
          Ember.set(_this, 'error', (0, _getErrorMessage.default)('error'));
        });
      }
    }
  });

  _exports.default = _default;
});
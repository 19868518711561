define("hospodari/components/service/input-service-widget-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    description: Ember.computed('item.description', function () {
      var description = this.get('item.description') ? this.get('item.description').trim() : '';
      if (!description) return '';
      return description.replace(/^[\d\-\s.]+/, '');
    })
  });

  _exports.default = _default;
});
define("hospodari/components/order/order-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Properties
    showObject: true,
    // Actions
    actions: {
      click: function click(order) {
        Ember.get(this, 'router').transitionTo('my.orders.item', order.id);
      }
    }
  });

  _exports.default = _default;
});
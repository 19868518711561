define("hospodari/templates/components/business/business-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fMgBKTeg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-muted\"],[8],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"mr-2\"],[8],[0,\"\\n    \"],[5,\"review/rate\",[],[[\"@rating\"],[[24,[\"item\",\"rating\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"small\",true],[8],[0,\"\\n    \"],[1,[24,[\"item\",\"rating_count\"]],false],[0,\"\\n    відгуків\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-rate.hbs"
    }
  });

  _exports.default = _default;
});
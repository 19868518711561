define("hospodari/components/input-service-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-group'],
    modalOpened: false,
    onOptionChanged: function onOptionChanged() {},
    actions: {
      handleServiceChange: function handleServiceChange(service) {
        this.setProperties({
          value: service,
          modalOpened: false
        });
        this.onOptionChanged(service);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/models/neuron", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    type_location: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    online: _emberData.default.attr('boolean'),
    language: _emberData.default.attr('string'),
    // Stats
    weight: _emberData.default.attr('number'),
    views: _emberData.default.attr('number'),
    subscriber_count: _emberData.default.attr('number'),
    // Subscribers count
    comment_count: _emberData.default.attr('number'),
    // Comments count
    axon_count: _emberData.default.attr('number'),
    // Axons count
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    startdate: _emberData.default.attr('date'),
    enddate: _emberData.default.attr('date'),
    // Type
    type: _emberData.default.attr('string'),
    // Display
    display: _emberData.default.attr('string'),
    // Icon
    icon_url: _emberData.default.attr('string'),
    // Image
    image_url: _emberData.default.attr('string'),
    // Video
    video_type: _emberData.default.attr('string'),
    video_url: _emberData.default.attr('string'),
    video_embed_url: _emberData.default.attr('string'),
    // Link
    link_url: Ember.computed('type', 'title', function () {
      var type = this.get('type');
      var url = this.get('title');
      return type === 'link' ? url : null;
    }),
    // Author
    author: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});
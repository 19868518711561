define("hospodari/components/bs-stepper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    step: 0,
    steps: null,
    linear: true,
    vertical: false,
    onChange: function onChange() {},
    actions: {
      handleStepChange: function handleStepChange(step) {
        this.onChange(step);
      }
    }
  });

  _exports.default = _default;
});
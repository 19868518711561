define("hospodari/templates/components/building/building-tiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JKJDFcYV",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"my.buildings.item\",[23,1,[\"property\",\"id\"]]]],{\"statements\":[[0,\"        \"],[1,[28,\"building/building-list-item\",null,[[\"item\"],[[23,1,[\"property\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/building/building-tiles.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/review/rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gaxs/aSm",
    "block": "{\"symbols\":[\"number\"],\"statements\":[[1,[28,\"if\",[[24,[\"value\"]],[24,[\"value\"]]],null],false],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[1,5,true],null]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[28,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"halfStar\"]]],null],\"star-half-o\",[28,\"if\",[[28,\"lte\",[[23,1,[]],[24,[\"value\"]]],null],\"star\",\"star-o\"],null]],null],\" text-success\"]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/review/rate.hbs"
    }
  });

  _exports.default = _default;
});
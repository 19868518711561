define("hospodari/components/order/order-apply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Actions
    actions: {
      apply: function apply() {
        var store = Ember.get(this, 'store');
        var created = moment();
        var order = Ember.get(this, 'order');
        var entity = Ember.get(this, 'entity');
        var customer = Ember.get(this, 'customer');
        var project = Ember.get(this, 'project');
        var product = Ember.get(this, 'product');
        var amount, description;
        amount = order.apply_amount;
        description = "\u0406\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u0456 \u043F\u043E\u0441\u043B\u0443\u0433\u0438 \u0437\u0430 \u043E\u0431\u0440\u043E\u0431\u043A\u0443 \u0443\u0433\u043E\u0434\u0438 #".concat(order.id);
        var invoice = store.createRecord('invoice', {
          created: created,
          seller: 'Hospodari Ukraine',
          fullname: entity.title,
          address: entity.address,
          email: entity.email,
          phone: entity.phone,
          amount: amount,
          currency: 'UAH',
          lines: Ember.A([Ember.Object.create({
            description: description,
            price: amount
          })]),
          customer: customer,
          project: project,
          product: product,
          order: order
        });
        invoice.save().then(function () {
          var paymentUrl = invoice.paymentUrl;
          var returnUrl = "http://local.hospodari.com/orders/".concat(order.id, "/payed");
          paymentUrl += "&return=" + encodeURIComponent(returnUrl);
          window.open(paymentUrl, '_blank'); // get(this, 'router').transitionTo('my.business.invoices', entity.id);
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/mixins/authenticated-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Services
    me: Ember.inject.service(),
    // Before Model
    beforeModel: function beforeModel() {
      var isAuthenticated = !!Ember.get(this, "me.id");
      var next = window.location.href;

      if (!isAuthenticated) {
        this.transitionTo("login", {
          queryParams: {
            next: next
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/my/buildings/buy", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        item: store.findRecord('property', params.id)
      });
    },
    actions: {
      invoiceCreated: function invoiceCreated(id) {
        this.transitionTo('my.buildings.invoices', id);
      }
    }
  });

  _exports.default = _default;
});
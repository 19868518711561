define("hospodari/routes/my/buildings/services", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Model
    model: function model(params) {
      var store = Ember.get(this, "store");
      return Ember.RSVP.hash({
        item: store.findRecord("property", params.id),
        // ToDo: replase mock with API call
        items: Promise.resolve([]),
        //store.query('propertyservice', {'filter[customer]': params.id}),
        subscriptionServices: store.query("service", {
          page: 1,
          per_page: 100,
          "filter[display]": "public",
          "filter[service_type]": "subscription",
          sort: "-title"
        }),
        emergencyServices: store.query("service", {
          page: 1,
          per_page: 100,
          "filter[display]": "public",
          "filter[service_type]": "emergency",
          sort: "title"
        }),
        propertyservices: store.query("propertyservice", {
          page: 1,
          per_page: 100,
          "filter[property]": params.id,
          sort: "-title"
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
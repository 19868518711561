define("hospodari/components/building/building-members-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    items: [{
      firstname: 'Артем',
      lastname: 'Карявка',
      fullname: 'Артем Карявка',
      role: 'Власник'
    }, {
      firstname: 'Максим',
      lastname: 'Бабич',
      fullname: 'Максим Бабич',
      role: 'Менеджер'
    }, {
      firstname: 'Денис',
      lastname: 'Скворцов',
      fullname: 'Денис Скворцов',
      role: 'Юрист'
    }]
  });

  _exports.default = _default;
});
define("hospodari/routes/my/business/orders", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Угоди',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      per_page: {
        refreshModel: true
      },
      city: {
        refreshModel: true
      },
      service: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var entityId = parseInt(params.id);
      var projectId = parseInt(_environment.default.api.project);
      var productId = parseInt(_environment.default.api.product);
      return store.findRecord('entity', entityId).then(function (entity) {
        var ordersParams = {
          include: 'service',
          'filter[project]': projectId,
          'filter[product]': productId,
          'filter[status]': 'opened',
          page: params.page,
          per_page: params.per_page
        };

        if (params.city) {
          ordersParams['filter[city]'] = params.city;
        }

        if (params.service) {
          ordersParams['filter[service]'] = params.service;
        }

        return Ember.RSVP.hash({
          item: entity,
          customer: store.findRecord('neuron', entityId),
          project: store.findRecord('project', projectId),
          product: store.findRecord('product', productId),
          orders: store.query('order', ordersParams),
          selectedService: params.service ? store.findRecord('service', params.service) : null
        });
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
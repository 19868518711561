define("hospodari/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    sections: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    // Stats
    views: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Type
    type: 'product',
    typeLabel: Ember.computed('type', function () {
      var type = Ember.get(this, 'type');
      return type[0].toUpperCase() + type.slice(1, type.length);
    }),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('neuron')
  });

  _exports.default = _default;
});
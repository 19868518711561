define("hospodari/components/phone/manager-contacts", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['media'],
    phones: _environment.default.contacts.forentities.slice(0, 1),
    manager: null,
    contactsClass: 'col-12',
    imageStyle: 'max-width: 60px;',
    imageClass: 'mr-3'
  });

  _exports.default = _default;
});
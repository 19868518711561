define("hospodari/templates/components/floating-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sbr3Kc4l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isOpened\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"floating-window shadow-lg\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"isOpened\",\"toggle\"],[[24,[\"isOpened\"]],[28,\"action\",[[23,0,[]],\"toggle\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-primary \",[28,\"if\",[[24,[\"media\",\"isResponsive\"]],\"\",\"btn-lg\"],null],\" floating-button shadow\"]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggle\",true]],[8],[0,\"\\n  \"],[1,[28,\"if\",[[24,[\"media\",\"isResponsive\"]],\"Замовити\",\"Заявка в 1 клік\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/floating-block.hbs"
    }
  });

  _exports.default = _default;
});
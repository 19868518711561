define("hospodari/templates/blog/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7sazCst",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[28,\"common/common-header\",null,[[\"search\",\"title\"],[false,\"Блог\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container mt-5 mb-5\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-4 mb-5\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"blog.item\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"card blog-item\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"card-img-top-custom\"],[11,\"style\",[23,1,[\"imageStyle\"]]],[8],[9],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"date mb-1\"],[8],[1,[28,\"moment-format\",[[23,1,[\"created\"]],\"DD.MM.YYYY\"],null],false],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"title mb-3\"],[8],[0,\"\\n                  \"],[1,[23,1,[\"title\"]],false],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"summary mb-3\"],[8],[1,[23,1,[\"summary\"]],false],[9],[0,\"\\n              \"],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n      \"],[1,[28,\"common/common-pager\",null,[[\"class\",\"align\",\"items\",\"current\"],[\"col-12\",\"center\",[24,[\"model\",\"items\"]],[24,[\"page\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"common/common-footer\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/blog/index.hbs"
    }
  });

  _exports.default = _default;
});
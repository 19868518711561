define("hospodari/models/invoice", ["exports", "ember-data", "hospodari/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var DEFAULT_TITLE = 'no title';

  var _default = Model.extend({
    uid: _emberData.default.attr('string'),
    seller: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    lines: _emberData.default.attr('array-of-objects'),
    // Status
    status: _emberData.default.attr('string'),
    statusLabel: Ember.computed('status', function () {
      return Ember.get(this, 'status').capitalize();
    }),
    // Relationships
    customer: _emberData.default.belongsTo('neuron'),
    project: _emberData.default.belongsTo('project'),
    product: _emberData.default.belongsTo('product'),
    order: _emberData.default.belongsTo('order'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Rights
    rights: _emberData.default.attr(),
    // Computed title
    title: Ember.computed('lines.@each.description', function () {
      var lines = Ember.get(this, 'lines');

      if (!(Array.isArray(lines) && lines.length)) {
        return DEFAULT_TITLE;
      }

      var line = lines.find(function (line) {
        return line && line.description;
      });
      return line && line.description || DEFAULT_TITLE;
    }),
    // URLs
    previewUrl: Ember.computed('uid', function () {
      var uid = Ember.get(this, 'uid');
      return "".concat(_environment.default.api.host, "/invoice/").concat(uid, "/preview/");
    }),
    printUrl: Ember.computed('uid', function () {
      var uid = Ember.get(this, 'uid');
      return "".concat(_environment.default.api.host, "/invoice/").concat(uid, "/pdf/");
    }),
    paymentUrl: Ember.computed('id', 'amount', 'currency', 'project.id', 'product.id', function () {
      var amount = Ember.get(this, 'amount');
      var currency = Ember.get(this, 'currency');
      var description = Ember.get(this, 'title');
      var invoice_id = Ember.get(this, 'id');
      var customer_id = Ember.get(this, 'customer.id');
      var project_id = Ember.get(this, 'project.id');
      var product_id = Ember.get(this, 'product.id');
      var order_id = Ember.get(this, 'order.id');
      var baseUrl = "".concat(_environment.default.api.host, "/payment/");
      var currencyPart = "&currency=".concat(currency);
      var invoicePart = "&invoice=".concat(invoice_id);
      var customerPart = "&customer=".concat(customer_id);
      var projectPart = "&project=".concat(project_id);
      var productPart = "&product=".concat(product_id);
      var orderPart = order_id > 0 ? "&order=".concat(order_id) : '';
      var descriptionPart = description ? "&description=" + encodeURIComponent(description) : '';
      return "".concat(baseUrl, "?amount=").concat(amount).concat(currencyPart).concat(invoicePart).concat(customerPart).concat(projectPart).concat(productPart).concat(orderPart).concat(descriptionPart);
    })
  });

  _exports.default = _default;
});
define("hospodari/components/offer/offer-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Service
    router: Ember.inject.service(),
    // Permissions
    canSelect: Ember.computed('order.status', function () {
      var status = Ember.get(this, 'order.status');
      var isStatusOk = status === 'opened';
      return isStatusOk;
    }),
    // Actions
    actions: {
      accept: function accept() {
        var _this = this;

        var property = Ember.get(this, 'property');
        var order = Ember.get(this, 'order');
        var offer = Ember.get(this, 'offer');
        var propertyId = parseInt(Ember.get(property, 'id'));
        var orderId = parseInt(Ember.get(order, 'id'));
        Ember.set(order, 'offer', offer);
        Ember.set(order, 'status', 'accepted');
        order.save().then(function () {
          Ember.get(_this, 'router').transitionTo('my.buildings.order', propertyId, orderId);
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/signup-building-owner-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IOpd57DV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"common/common-header\",null,[[\"search\",\"title\"],[false,\"\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container mt-4 mb-4\"],[8],[0,\"\\n\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-center mb-5\"],[8],[0,\"\\n    Вітаємо з успішною реєстрацією!\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center mb-4\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Ви успішно зареєструвалися. Тепер додайте будинок.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"query\",\"route\"],[\"btn btn-lg btn-primary mb-5\",[28,\"hash\",null,[[\"type\"],[null]]],\"my.buildings.new\"]],{\"statements\":[[0,\"      Додати будинок\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center text-muted\"],[10,\"style\",\"font-size: 90%;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 text-center\"],[8],[0,\"\\n      Також ви можете\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto px-2 py-2\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"my.profile\"]],{\"statements\":[[0,\"        Переглянути профіль\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-auto px-2 py-2\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"my\"]],{\"statements\":[[0,\"        Перейти в кабінет\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/signup-building-owner-complete.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/business/business-activation-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Computed
    canEdit: Ember.computed.reads('item.rights.can_edit')
  });

  _exports.default = _default;
});
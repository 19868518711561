define("hospodari/templates/components/order/order-apply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rYEnX5wx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\n    \"],[1,[28,\"order/order-item-brief\",null,[[\"order\"],[[24,[\"order\"]]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"row mb-5\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-lg btn-danger btn-max\",\"my.business.item\",[24,[\"entity\",\"id\"]]]],{\"statements\":[[0,\"          Відхилити\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-lg btn-success btn-max\"],[3,\"action\",[[23,0,[]],\"apply\"]],[8],[0,\"\\n          Прийняти\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-3\"],[8],[0,\"\\n    \"],[7,\"h5\",true],[10,\"class\",\"mb-4\"],[8],[0,\"Потрібна консультація менеджера?\"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"mb-5\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"tel:+380678072071\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-phone\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n        +38 067 807 20 71\\n      \"],[9],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"mailto:support@hospodari.com\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-envelope\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n        support@hospodari.com\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/order/order-apply.hbs"
    }
  });

  _exports.default = _default;
});
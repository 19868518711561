define("hospodari/templates/buildings/item/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BDhBBprU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"h3 mt-0 text-center mb-\"],[8],[0,\"\\n  \"],[1,[28,\"if\",[[28,\"eq\",[[24,[\"model\",\"item\",\"property_type\"]],\"condominium\"],null],\"ОСББ\",\"Нерухомість\"],null],false],[0,\"\\n  \\\"\"],[1,[24,[\"model\",\"item\",\"title\"]],false],[0,\"\\\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"text-muted lead mb-5 text-center\"],[8],[0,\"\\n  \"],[1,[28,\"full-address\",[[24,[\"model\",\"item\"]]],[[\"showCity\"],[true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"item\",\"property_type\"]],\"condominium\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-6 col-lg-4\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"btn btn-custom btn-lg btn-block\",\"buildings.item.join\",[24,[\"model\",\"item\",\"id\"]]]],{\"statements\":[[0,\"        Приєднатися\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/buildings/item/index.hbs"
    }
  });

  _exports.default = _default;
});
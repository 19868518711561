define("hospodari/utils/gtag_report_conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = gtag_report_conversion;

  function gtag_report_conversion(send_to, url) {
    if (window.location.hostname === 'www.hospodari.com') {
      var callback = function callback() {
        if (typeof url != 'undefined') {
          window.location = url;
        }
      };

      if (typeof gtag == 'function') {
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', {
          send_to: send_to,
          event_callback: callback
        });
      }
    }

    return false;
  }
});
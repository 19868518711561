define("hospodari/routes/my/buildings/item", ["exports", "hospodari/mixins/authenticated-route-mixin", "hospodari/utils/get-filters-from-params", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _getFiltersFromParams, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.item.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var grant_params = {};
      grant_params['site'] = _environment.default.api.site;
      grant_params['per_page'] = 12;
      grant_params['display'] = 'public';
      grant_params['featured'] = true;
      grant_params['lira'] = 157830;
      var grant_filters = (0, _getFiltersFromParams.default)(grant_params);
      return Ember.RSVP.hash({
        item: store.findRecord('property', params.id),
        grants: store.query('post', grant_filters)
      });
    }
  });

  _exports.default = _default;
});
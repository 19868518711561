define("hospodari/models/propertyservice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    amount: _emberData.default.attr('number'),
    // active, paused, finished, deleted, archived
    status: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    started: _emberData.default.attr('datetime'),
    finished: _emberData.default.attr('datetime'),
    deleted: _emberData.default.attr('datetime'),
    archived: _emberData.default.attr('datetime'),
    // Author
    author: _emberData.default.belongsTo('user'),
    property: _emberData.default.belongsTo('property'),
    service: _emberData.default.belongsTo('service'),
    // Computed
    title: Ember.computed.reads('service.title')
  });

  _exports.default = _default;
});
define("hospodari/utils/get-language-label", ["exports", "livarava-framework/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getLanguageLabel;

  function getLanguageLabel(language) {
    var out = null;
    var languages = _environment.default.i18n.localeLabels;
    languages.forEach(function (_language) {
      if (_language.value === language) {
        out = _language.label;
      }
    });
    return out;
  }
});
define("hospodari/templates/components/business/business-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e5IyQz7H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card business-invoices p-5\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"business-aside col-12 col-md-3\"],[8],[0,\"\\n\\n      \"],[1,[28,\"business/business-navigation\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n\\n      \"],[1,[28,\"business/business-activation-message\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"row mb-3\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col text-left\"],[8],[0,\"\\n          \"],[7,\"h4\",true],[10,\"class\",\"title\"],[8],[0,\"\\n            Рахунки\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col text-right\"],[8],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"business/business-invoices-list\",null,[[\"items\",\"entity\"],[[24,[\"items\"]],[24,[\"item\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"hr\",true],[8],[9],[0,\"\\n        У вас ще немає сформованих рахунків.\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-invoices.hbs"
    }
  });

  _exports.default = _default;
});
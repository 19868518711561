define("hospodari/templates/components/service/service-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BS2DDspl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[24,[\"serviceType\",\"title\"]],false],[0,\" \"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/service/service-type.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/common/conditions-date-checker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tGLZVLMZ",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"bs-modal\",[],[[\"@open\",\"@backdropClose\",\"@scrollable\",\"@size\",\"@onSubmit\"],[[22,\"isOutdated\"],false,true,\"lg\",[28,\"action\",[[23,0,[]],\"update\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[\"@closeButton\"],[false]],{\"statements\":[[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-info-circle text-info\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      Оновлені \\\"Умови використання\\\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"froala-content\",null,null,{\"statements\":[[0,\"      \"],[1,[24,[\"page\",\"description\"]],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"success\",[28,\"action\",[[23,0,[]],[23,1,[\"submit\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-check\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      Приймаю умови\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/common/conditions-date-checker.hbs"
    }
  });

  _exports.default = _default;
});
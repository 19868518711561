define("hospodari/routes/restore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Params
    queryParams: {
      email: {
        refreshModel: false
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('code', null);
    },
    // Actions
    actions: {
      toPasswordChange: function toPasswordChange(params) {
        this.transitionTo('password', {
          queryParams: params
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/business/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var _this = this;

      var store = Ember.get(this, 'store');
      var id = params.id;
      return Ember.RSVP.hash({
        item: store.findRecord('entity', id).then(function (item) {
          Ember.set(_this, 'titleToken', Ember.get(item, 'title'));
          return item;
        }).catch(function () {
          return null;
        }),
        posts: store.query('post', {
          lira: id
        }),
        services: store.query('entityservice', {
          'filter[entity]': params.id,
          page: 1,
          per_page: 100
        }).then(function (items) {
          return Promise.all(items.mapBy('service'));
        }),
        reviews: store.query('review', {
          'filter[entity]': id
        })
      });
    }
  });

  _exports.default = _default;
});
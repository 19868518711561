define("hospodari/models/action", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Neuron
    type: _emberData.default.attr('string'),
    // Stats
    weight: _emberData.default.attr('number'),
    views: _emberData.default.attr('number'),
    subscriber_count: _emberData.default.attr('number'),
    // Subscribers count
    comment_count: _emberData.default.attr('number'),
    // Comments count
    axon_count: _emberData.default.attr('number'),
    // Axons count
    order_count: _emberData.default.attr('number'),
    // Orders count
    // Datetime
    created: _emberData.default.attr('datetime'),
    scheduled: _emberData.default.attr('datetime'),
    // General
    //call, email, sms, meeting, etc.
    action_type: _emberData.default.attr('string'),
    // out, in, system
    action_direction: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    // Relations
    author: _emberData.default.belongsTo('user'),
    property: _emberData.default.belongsTo('property'),
    entity: _emberData.default.belongsTo('entity'),
    order: _emberData.default.belongsTo('order'),
    project: _emberData.default.belongsTo('project'),
    product: _emberData.default.belongsTo('product'),
    customer: _emberData.default.belongsTo('user'),
    manager: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});
define("hospodari/models/transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Neuron
    type: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    // General
    transaction_type: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    balance_before: _emberData.default.attr('number'),
    balance_after: _emberData.default.attr('number'),
    // Relations
    author: _emberData.default.belongsTo('user'),
    customer: _emberData.default.belongsTo('neuron'),
    project: _emberData.default.belongsTo('project'),
    product: _emberData.default.belongsTo('product'),
    order: _emberData.default.belongsTo('order'),
    entity: _emberData.default.belongsTo('entity')
  });

  _exports.default = _default;
});
define("hospodari/controllers/my/business/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'per_page', 'city', 'service'],
    page: 1,
    per_page: 10,
    city: null,
    service: null,
    resetController: function resetController(controller, isExiting
    /*transition*/
    ) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.setProperties({
          page: 1,
          city: null,
          service: null
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/pages/avariyna-turn-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    properties: null,
    loadProperties: function loadProperties() {
      var _this = this;

      var me = this.get('me');

      if (!me.signed) {
        this.set('properties', null);
        return;
      }

      var store = this.get('store');
      var userId = parseInt(me.id);
      store.query('propertyuser', {
        'filter[user]': userId
      }).then(function (propertyusers) {
        return _this.set('properties', propertyusers);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.loadProperties();
    }
  });

  _exports.default = _default;
});
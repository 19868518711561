define("hospodari/models/axon", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Display
    display: _emberData.default.attr('string'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    liva: _emberData.default.belongsTo('neuron'),
    rava: _emberData.default.belongsTo('neuron'),
    lira: _emberData.default.belongsTo('neuron')
  });

  _exports.default = _default;
});
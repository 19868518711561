define("hospodari/components/input-with-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getError(errors, field) {
    if (!errors) return null;
    if (!field) return null;
    var error = errors.find(function (_ref) {
      var attribute = _ref.attribute;
      return attribute === field;
    });
    if (!error) return null;
    return error.message.join();
  }

  var _default = Ember.Component.extend({
    error: Ember.computed('item.errors.[]', function () {
      var id = Ember.get(this, 'id');
      var errors = Ember.get(this, 'item.errors');
      return getError(errors, id);
    }),
    klass: Ember.computed('error', 'item.isValidated', function () {
      var error = Ember.get(this, 'error');
      var isValidated = Ember.get(this, 'item.isValidated');
      if (!isValidated) return 'form-control';
      return error ? 'form-control is-invalid' : 'form-control is-valid';
    })
  });

  _exports.default = _default;
});
define("hospodari/components/order/order-create-form", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectSpread2", "moment", "hospodari/utils/get-error-message"], function (_exports, _slicedToArray2, _objectSpread2, _moment, _getErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Attributes
    item: null,
    // changeset
    error: null,
    loading: false,
    step: 0,
    subStep: 1,
    propertyusers: null,
    entity: null,
    entityServices: null,
    // default event handlers
    onDirtyChanged: function onDirtyChanged() {},
    onCreated: function onCreated() {},
    onStepChange: function onStepChange() {},
    onSubStepChange: function onSubStepChange() {},
    // Computed
    applied: Ember.computed('item.apply_deadline', function () {
      var momentDate = Ember.get(this, 'item.apply_deadline') || (0, _moment.default)();
      return momentDate.toDate();
    }),
    finaled: Ember.computed('item.final_deadline', function () {
      var momentDate = Ember.get(this, 'item.final_deadline') || (0, _moment.default)();
      return momentDate.toDate();
    }),
    fetchProperies: function fetchProperies() {
      var _this = this;

      this.set('loading', true);
      return this.store.query('propertyuser', {
        'filter[user]': this.get('me.id')
      }).then(function (propertyusers) {
        _this.set('loading', false);

        _this.set('propertyusers', propertyusers);

        return propertyusers;
      }).catch(function () {
        _this.set('loading', false);
      });
    },
    fetchEntityServices: function fetchEntityServices() {
      var _this2 = this;

      this.store.query('entityservice', {
        'filter[entity]': this.get('entity.id'),
        page: 1,
        per_page: 100
      }).then(function (entityservices) {
        return Promise.all(entityservices.mapBy('service').uniqBy('id'));
      }).then(function (services) {
        return _this2.set('entityServices', services.sortBy('title'));
      });
    },
    setStep: function setStep(step) {
      this.set('step', step);
      this.set('error', null);
      this.onDirtyChanged(step > 0);
      this.onStepChange(step);

      if (this.subStep > 1) {
        this.onSubStepChange(1);
      }
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this.fetchProperies(); // move to second sep if `item.property` is set

      if (this.get('item.property.id')) {
        Ember.run.next(function () {
          _this3.setStep(_this3.get('item.service.id') ? 2 : 1);
        });
      }

      if (this.get('entity.id')) {
        this.fetchEntityServices();
      }
    },
    // Actions
    actions: {
      updateDate: function updateDate(a, b) {
        Ember.set(this, a, (0, _moment.default)(b));
      },
      objectChanged: function objectChanged(property) {
        Ember.set(this, 'item.address', Ember.get(property, 'address'));
        Ember.set(this, 'item.address_area_level_1', Ember.get(property, 'address_area_level_1'));
        Ember.set(this, 'item.address_area_level_2', Ember.get(property, 'address_area_level_2'));
        Ember.set(this, 'item.address_postal_code', Ember.get(property, 'address_postal_code'));
        Ember.set(this, 'item.city', Ember.get(property, 'city'));
        Ember.set(this, 'item.address_type', Ember.get(property, 'address_type'));
        Ember.set(this, 'item.address_name', Ember.get(property, 'address_name'));
        Ember.set(this, 'item.address_building', Ember.get(property, 'address_building'));
        Ember.set(this, 'item.address_apartment', Ember.get(property, 'address_apartment'));
      },
      handleProperyChange: function handleProperyChange(property) {
        this.item.setProperties((0, _objectSpread2.default)({
          property: property
        }, property.getProperties('address', 'address_area_level_1', 'address_area_level_2', 'address_postal_code', 'city', 'address_type', 'address_name', 'address_building', 'address_apartment')));
        this.setStep(this.get('item.service.id') ? 2 : 1);
      },
      handleServiceChange: function handleServiceChange(service) {
        this.item.setProperties({
          service: service
        });
        this.setStep(2);
      },
      handleFormCheck: function handleFormCheck() {
        var _this4 = this;

        var item = this.item;
        return item.validate().then(function () {
          if (item.get('isValid')) {
            _this4.setStep(3);
          }
        });
      },
      save: function save(item) {
        var _this5 = this;

        var entity = this.entity,
            store = this.store;
        return item.validate().then(function () {
          if (item.get('isValid')) {
            if (_this5.step < 3) {
              _this5.setStep(_this5.step + 1);

              return;
            }

            return item.save().then(function (item) {
              if (entity) {
                var offer = store.createRecord('offer', (0, _objectSpread2.default)({
                  amount: item.get('amount'),
                  order: item,
                  entity: entity,
                  status: 'accepted'
                }, _this5.getProperties('project', 'product')));
                return offer.save().then(function () {
                  return Promise.all([item.get('property.manager'), store.findRecord('user', 153073)]);
                }).then(function (_ref) {
                  var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
                      propertyManager = _ref2[0],
                      defaultManager = _ref2[1];

                  // При замовленні з каталогу проставляти статус заявки "прийнято".
                  // Ставити менеджером заявки менеджера нерухомості, а якщ пустий - то Вікторію
                  item.setProperties({
                    status: 'accepted',
                    manager: propertyManager && propertyManager.get('id') ? propertyManager : defaultManager
                  });
                  return item.save();
                }).catch(function () {}).then(function () {
                  return item;
                });
              }

              return item;
            }).then(function (item) {
              _this5.onDirtyChanged(false);

              _this5.onCreated(item);
            }).catch(function (data) {
              var error = JSON.parse(data.errors[0].detail).error.message; // console.log(error);

              Ember.set(_this5, 'error', (0, _getErrorMessage.default)(error));
            });
          }
        });
      },
      setStep: function setStep(step) {
        this.setStep(step);
      },
      handleSubStepChange: function handleSubStepChange(subStep) {
        this.onSubStepChange(subStep);
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/business/business-services-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Actions
    actions: {
      delete: function _delete() {
        var _this = this;

        var item = this.get('item');

        if (confirm("Ви дійсно бажаєте видалити послугу?")) {
          item.destroyRecord().then(function () {
            _this.get('onEntityServiceDeleted')();
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/input-property-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Actions
    actions: {
      optionChanged: function optionChanged(a) {
        var _this = this;

        var store = Ember.get(this, 'store');
        var itemId = parseInt(a);
        var items = Ember.get(this, 'items');
        items.forEach(function (item) {
          var propertyId = parseInt(Ember.get(item, 'property.id'));

          if (propertyId === itemId) {
            return store.findRecord('property', propertyId).then(function (property) {
              Ember.set(_this, 'value', property);
              Ember.get(_this, 'optionChanged')(property);
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});
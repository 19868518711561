define("hospodari/models/user", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Attributes
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    about: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    sections: _emberData.default.attr(),
    online: _emberData.default.attr('boolean'),
    city: _emberData.default.attr('string'),
    // Type
    type: 'user',
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    hospodari_conditions_accepted: _emberData.default.attr('datetime'),
    // Icon
    icon: _emberData.default.attr('string'),
    icon_url: _emberData.default.attr('string'),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("hospodari/components/phone/phone-modal", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    phonesForEntities: _environment.default.contacts.forentities
  });

  _exports.default = _default;
});
define("hospodari/templates/components/business/business-blacklisted-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gz1UR+CP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[10,\"role\",\"alert\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Платформа зафіксувала порушення правил та умов використання сайту (угоди користувача), що є публічним договором (офертою) з якою ви погодилися під час реєстрації.\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    У разі системних порушень цих правил до вас можуть бути застосовані наступні санкції\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    1-е порушення – доступ до замовлень блокується на сім днів;\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    2-е порушення – доступ до замовлень блокується на тридцять днів;\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    3-є порушення – доступ до замовлень блокується на 365 днів (також додатково можуть бути застосовані санкції у вигляді повідомлення на Сайті та у соціальних мережах відносно недобросовісності підрядника).\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    Просимо вас негайно зв’язатися із вашим менеджером для вирішення конфліктної ситуації.\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-blacklisted-message.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/models/page", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    display: _emberData.default.attr('string'),
    featured: _emberData.default.attr('boolean'),
    language: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    path: _emberData.default.attr('string'),
    // Stats
    weight: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    views: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    subscriber_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Subscribers count
    comment_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Comments count
    axon_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Axons count
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    site: _emberData.default.belongsTo('site'),
    image: _emberData.default.belongsTo('neuron'),
    // Meta
    meta_title: _emberData.default.attr('string'),
    meta_description: _emberData.default.attr('string'),
    meta_keywords: _emberData.default.attr('string'),
    meta_image: _emberData.default.belongsTo('neuron'),
    meta_image_url: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
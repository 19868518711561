define("hospodari/components/index/index-catalog", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    buildingTypes: _environment.default.building.types,
    categories: null,
    loading: true,
    selectedTab: 0,
    fetchCategories: function fetchCategories() {
      var _this = this;

      return this.get('store').query('service', {
        'filter[parent]': 'null',
        'filter[display]': 'public',
        'filter[service_type]': 'job',
        per_page: 100,
        sort: 'order'
      }).then(function (categories) {
        return !_this.isDestroyed && _this.set('categories', categories);
      });
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.fetchCategories().finally(function () {
        return !_this2.isDestroyed && _this2.set('loading', false);
      });
    }
  });

  _exports.default = _default;
});
define("hospodari/routes/my/buildings/new", ["exports", "hospodari/mixins/authenticated-route-mixin", "moment", "hospodari/config/environment"], function (_exports, _authenticatedRouteMixin, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cities = _environment.default.address.cities;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Params
    queryParams: {
      type: {
        refreshModel: true
      }
    },
    // Title
    titleToken: "Новий об'єкт",
    // Model
    model: function model(params) {
      var store = this.get('store');
      var firstname = Ember.get(this, 'me.firstname');
      var lastname = Ember.get(this, 'me.lastname');
      var phone = Ember.get(this, 'me.phone');
      var email = Ember.get(this, 'me.email');
      var city = Ember.get(this, 'me.city');
      var cityDetails = cities.find(function (c) {
        return c.name == city;
      }) || {};
      var address_area_level_1 = cityDetails.address_area_level_1,
          address_area_level_2 = cityDetails.address_area_level_2,
          address_postal_code = cityDetails.address_postal_code;
      var item = store.createRecord('property', {
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        email: email,
        city: city,
        address_area_level_1: address_area_level_1,
        address_area_level_2: address_area_level_2,
        address_postal_code: address_postal_code,
        created: (0, _moment.default)(),
        apply_deadline: (0, _moment.default)(),
        final_deadline: (0, _moment.default)()
      });
      return Ember.RSVP.hash({
        item: item
      });
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/building/building-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UtHp09+9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-muted\"],[8],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"mr-2\"],[8],[0,\"\\n    5 \"],[7,\"i\",true],[10,\"class\",\"fa fa-star-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[7,\"i\",true],[10,\"class\",\"fa fa-star-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[7,\"i\",true],[10,\"class\",\"fa fa-star-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[7,\"i\",true],[10,\"class\",\"fa fa-star-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[7,\"i\",true],[10,\"class\",\"fa fa-star-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"small\",true],[8],[0,\"\\n    0 відгуків\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/building/building-rate.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/building/building-condominium-form", ["exports", "hospodari/utils/get-error-message", "hospodari/helpers/full-address"], function (_exports, _getErrorMessage, _fullAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Flags
    isNew: null,
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;

        item.validate().then(function () {
          if (item.get("isValid")) {
            Ember.set(item, "property_type", "condominium");
            var isNew = Ember.get(_this, "item.isNew");
            return item.save().then(function (item) {
              if (isNew) {
                Ember.get(_this, "router").transitionTo("signup-building-condominium-complete");
              } else {
                Ember.get(_this, "router").transitionTo("my.buildings.item", item.id);
              }
            }).catch(function (data) {
              var error = JSON.parse(data.errors[0].detail).error.message;
              console.log(error);
              Ember.set(_this, "error", (0, _getErrorMessage.default)(error));
            });
          }
        });
      },
      delete: function _delete() {
        var _this2 = this;

        if (confirm("Ви дійсно бажаєте видалити це об'єднання?")) {
          var item = Ember.get(this, 'item');
          item.destroyRecord().then(function (item) {
            Ember.get(_this2, 'router').transitionTo('my.buildings.index');
          }).catch(function (data) {
            var error = JSON.parse(data.errors[0].detail).error.message;
            console.log(error);
            Ember.set(_this2, 'error', (0, _getErrorMessage.default)(error));
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/controllers/my/business/new", ["exports", "hospodari/validations/business-validations"], function (_exports, _businessValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    businessValidations: _businessValidations.default
  });

  _exports.default = _default;
});
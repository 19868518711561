define("hospodari/components/business/business-services", ["exports", "hospodari/validations/entityservice-validations"], function (_exports, _entityserviceValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    entityServiceValidations: _entityserviceValidations.default,
    // Services
    store: Ember.inject.service(),
    // Attributes
    error: null,
    services: null,
    newEntityService: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newEntityService', {});
    },
    // Actions
    actions: {}
  });

  _exports.default = _default;
});
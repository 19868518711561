define("hospodari/components/business/business-order", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Properties
    rate: Ember.computed('rates', function () {
      var rates = Ember.get(this, 'rates');
      var rate = 28;
      rates.forEach(function (currency) {
        if (currency['cc'] === 'USD') {
          rate = currency['rate'];
        }
      });
      return rate;
    }),
    brand1: 299,
    brand2: 499,
    brand3: 899,
    catalog1: 799,
    catalog2: 1399,
    catalog3: 2499,
    deposit1: 5000,
    deposit2: 10000,
    deposit3: 15000,
    // Actions
    actions: {
      createInvoice: function createInvoice(order) {
        var _this = this;

        var store = Ember.get(this, 'store');
        var created = (0, _moment.default)();
        var customer = Ember.get(this, 'customer');
        var project = Ember.get(this, 'project');
        var product = Ember.get(this, 'product');
        var amount, description, price;

        if (order === 'activation_0') {
          amount = 0.01;
          price = amount;
          description = 'Інформаційні послуги акційна підписка на платформу Господарі';
        } else if (order === 'catalog1') {
          amount = Ember.get(this, 'catalog1');
          price = amount;
          description = 'Інформаційні послуги Каталог Плюс на платформі Господарі';
        } else if (order === 'catalog2') {
          amount = Ember.get(this, 'catalog2');
          price = amount;
          description = 'Інформаційні послуги Каталог Плюс на платформі Господарі';
        } else if (order === 'catalog3') {
          amount = Ember.get(this, 'catalog3');
          price = amount;
          description = 'Інформаційні послуги Каталог Плюс на платформі Господарі';
        } else if (order === 'brand1') {
          amount = Ember.get(this, 'brand1');
          price = amount;
          description = 'Інформаційні послуги Бренд Плюс на платформі Господарі';
        } else if (order === 'brand2') {
          amount = Ember.get(this, 'brand2');
          price = amount;
          description = 'Інформаційні послуги Бренд Плюс на платформі Господарі';
        } else if (order === 'brand3') {
          amount = Ember.get(this, 'brand3');
          price = amount;
          description = 'Інформаційні послуги Бренд Плюс на платформі Господарі';
        } else if (order === 'deposit1') {
          amount = Ember.get(this, 'deposit1');
          price = amount;
          description = 'Інформаційні послуги Депозит Плюс на платформі Господарі';
        } else if (order === 'deposit2') {
          amount = Ember.get(this, 'deposit2');
          price = amount;
          description = 'Інформаційні послуги Депозит Плюс на платформі Господарі';
        } else if (order === 'deposit3') {
          amount = Ember.get(this, 'deposit3');
          price = amount;
          description = 'Інформаційні послуги Депозит Плюс на платформі Господарі';
        } else {
          return;
        }

        var invoice = store.createRecord('invoice', {
          created: created,
          seller: 'Hospodari Ukraine',
          fullname: Ember.get(this, 'item.title'),
          address: Ember.get(this, 'item.address'),
          email: Ember.get(this, 'item.email'),
          phone: Ember.get(this, 'item.phone'),
          amount: amount,
          currency: 'UAH',
          lines: Ember.A([Ember.Object.create({
            description: description,
            price: price
          })]),
          customer: customer,
          project: project,
          product: product
        });
        invoice.save().then(function () {
          window.open(invoice.paymentUrl, '_blank');
          Ember.get(_this, 'onInvoiceCreated')();
        });
      }
    }
  });

  _exports.default = _default;
});
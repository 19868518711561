define("hospodari/routes/buildings/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Title
    titleToken: Ember.computed.reads('model.item.title'),
    // Model
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        item: store.findRecord('property', params.id)
      });
    }
  });

  _exports.default = _default;
});
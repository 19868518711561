define("hospodari/components/transaction/transactions-list", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    typeOptions: _environment.default.transaction.type
  });

  _exports.default = _default;
});
define("hospodari/components/order/quick-order", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-fetch/ajax", "hospodari/config/environment", "hospodari/validations/quick-order-validations", "hospodari/utils/gtag_report_conversion"], function (_exports, _objectSpread2, _ajax, _environment, _quickOrderValidations, _gtag_report_conversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    quickOrderValidations: _quickOrderValidations.default,
    // Service
    me: Ember.inject.service(),
    tagName: '',
    onClose: function onClose() {},
    status: null,
    lead: null,
    init: function init() {
      this._super.apply(this, arguments);

      var user = this.get('me.user');
      var leadData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.getUTMParams()), {}, {
        title: 'Заявка в 1 клік: ' + new Date().toLocaleString(),
        funnel: 'new',
        summary: '',
        phone: '',
        firstname: '',
        utm_referral: window.location.href
      });

      if (user) {
        Object.assign(leadData, (0, _objectSpread2.default)({}, user.getProperties('firstname', 'lastname', 'phone', 'email', 'city')));
      }

      this.set('lead', leadData);
    },
    getUTMParams: function getUTMParams() {
      if (!location.search) {
        return {};
      }

      var urlQueryParams = location.search.replace('?', '').split('&').reduce(function (s, c) {
        var t = c.split('=');
        s[t[0]] = decodeURIComponent(t[1]);
        return s;
      }, {});

      if (urlQueryParams.gclid) {
        urlQueryParams.utm_source = 'google';
      } else if (urlQueryParams.fbclid) {
        urlQueryParams.utm_source = 'facebook';
      }

      return urlQueryParams;
    },
    actions: {
      close: function close() {
        this.onClose();
      },
      save: function save(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            var _config$api = _environment.default.api,
                project = _config$api.project,
                host = _config$api.host,
                path = _config$api.path;

            _this.set('status', 'loading');

            changeset.save();
            var leadData = {
              data: {
                attributes: changeset.get('data'),
                relationships: {
                  project: {
                    data: {
                      type: 'projects',
                      id: project
                    }
                  }
                },
                type: 'leads'
              }
            };

            if (_this.get('me.signed')) {
              leadData.data.relationships.author = {
                data: {
                  type: 'users',
                  id: _this.get('me.id')
                }
              };
            }

            return (0, _ajax.default)("".concat(host).concat(path, "/leads"), {
              method: 'POST',
              // *GET, POST, PUT, DELETE, etc.
              mode: 'cors',
              headers: {
                'Content-Type': 'application/vnd.api+json' // 'X-LivaRava-ApiKey': '4940950b-7fad-4a2c-9401-54f4dee71755',

              },
              body: JSON.stringify(leadData)
            }).then(function () {
              _this.set('status', 'success');

              (0, _gtag_report_conversion.default)('AW-616760782/Md-ICKjzqPwCEM6LjKYC');
            }).catch(function () {
              _this.set('error', 'Помилка! Будь ласка, зверніться до admin@livarava.com');

              _this.set('status', 'error');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/balance/top-up", ["exports", "ember-changeset-validations", "ember-changeset", "ember-changeset-validations/validators", "hospodari/config/environment"], function (_exports, _emberChangesetValidations, _emberChangeset, _validators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var topUpValidations = {
    amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      gte: 10,
      lt: 10e9
    })]
  };

  var _default = Ember.Component.extend({
    topUpValidations: topUpValidations,
    item: null,
    customerId: null,
    defaultAmount: 100,
    amountExamples: null,
    customerDetails: null,
    initItem: function initItem() {
      var _this = this;

      this.get('store').findRecord(this.get('customer.type'), this.customerId).then(function (customerDetails) {
        var balance = customerDetails.balance;

        var defaultAmount = _this.get('defaultAmount');

        _this.set('item', new _emberChangeset.default({
          amount: balance < -1 * defaultAmount ? Math.abs(balance) : defaultAmount
        }, (0, _emberChangesetValidations.default)(topUpValidations), topUpValidations));

        _this.set('customerDetails', customerDetails);
      });
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var store = Ember.get(this, 'store');
      Ember.RSVP.hash({
        customer: store.findRecord('neuron', this.customerId),
        project: store.findRecord('project', _environment.default.api.project),
        product: store.findRecord('product', _environment.default.api.product)
      }).then(function (properties) {
        _this2.setProperties(properties);

        _this2.initItem();
      });
      this.set('amountExamples', [500, 1000, 2000]);
    },
    actions: {
      submit: function submit(item) {
        var _this3 = this;

        return item.validate().then(function () {
          if (item.get('isValid')) {
            // apply changeset
            item.save();
            var amount = item.get('amount');
            var store = Ember.get(_this3, 'store');
            var invoice = store.createRecord('invoice', {
              seller: 'Hospodari Ukraine',
              fullname: Ember.get(_this3, 'customerDetails.title'),
              address: Ember.get(_this3, 'customerDetails.address'),
              email: Ember.get(_this3, 'customerDetails.email'),
              phone: Ember.get(_this3, 'customerDetails.phone'),
              amount: amount,
              currency: 'UAH',
              lines: Ember.A([Ember.Object.create({
                description: 'Інформаційні послуги на платформі Господарі',
                price: amount
              })]),
              customer: _this3.get('customer'),
              project: _this3.get('project'),
              product: _this3.get('product')
            });
            return invoice.save().then(function () {
              window.open(invoice.paymentUrl, '_blank');
              Ember.get(_this3, 'onInvoiceCreated')();
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/components/building/building-services-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    service: null,
    property: null,
    propertyservices: null,
    collapsed: true,
    active: false,
    iconSize: '2',
    visibleCharactersLimit: 130,
    isSaving: false,
    confirmed: false,
    propertyservice: Ember.computed('service.id', 'propertyservices.@each.service', function () {
      var _this = this;

      var propertyservices = this.propertyservices;
      if (!propertyservices || !propertyservices.length) return false;
      return propertyservices.find(function (ps) {
        return ps.get('service.id') == _this.get('service.id');
      });
    }),
    isActive: Ember.computed.equal('propertyservice.status', 'active'),
    hasMoreText: Ember.computed('service.summary', 'visibleCharactersLimit', function () {
      return this.get('service.summary.length') > this.get('visibleCharactersLimit');
    }),
    amountToBorrow: Ember.computed('property.balance', 'service.amount', function () {
      return this.get('service.amount') - this.get('property.balance');
    }),
    actions: {
      toggleService: function toggleService() {
        var _this2 = this;

        this.setProperties({
          showModal: false,
          confirmed: false
        });

        if (typeof this.get('onChange') == 'function') {
          this.toggleProperty('isSaving');
          return this.get('onChange')(this.get('service'), this.get('propertyservice')).then(function () {
            return _this2.set('isSaving', false);
          }).catch(function () {
            return _this2.set('isSaving', false);
          });
        }
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('showModal');
      },
      toggleCollapsed: function toggleCollapsed() {
        this.toggleProperty('collapsed');
      }
    }
  });

  _exports.default = _default;
});
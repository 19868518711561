define("hospodari/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    me: Ember.inject.service(),
    // Params
    queryParams: {
      next: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var isAuthenticated = !!Ember.get(this, "me.id");

      if (isAuthenticated) {
        this.transitionTo("index");
      }

      var next = params.next;
      return Ember.RSVP.hash({
        next: next
      });
    }
  });

  _exports.default = _default;
});
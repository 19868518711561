define("hospodari/templates/components/input-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7p4iH+xr",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[[24,[\"type\"]],[24,[\"klassComputed\"]],[24,[\"placeholder\"]],[24,[\"value\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showOptions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dropdown\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"dropdown-menu \",[28,\"if\",[[24,[\"items\"]],\"show\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",false],[3,\"action\",[[23,0,[]],\"select\",[23,1,[]]]],[8],[0,\"\\n          \"],[7,\"a\",true],[10,\"href\",\"\"],[10,\"class\",\"dropdown-item\"],[10,\"role\",\"button\"],[8],[0,\"\\n            \"],[1,[23,1,[\"title\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/input-service.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/validations/signup-validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 2,
      max: 20
    })],
    lastname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 2,
      max: 20
    })],
    phone: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^\+380\d{9}$/,
      message: 'Невірний формат. Приклад: +380991112233'
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    })],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      regex: /^[\w]+$/,
      message: 'Пароль може містити лише латинські літери і цифри'
    }), (0, _validators.validateLength)({
      min: 6,
      max: 16
    })]
  };
  _exports.default = _default;
});
define("hospodari/routes/my/buildings/offer", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.item.title'),
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var propertyId = parseInt(params.id);
      var orderId = parseInt(params.order_id);
      var offerId = parseInt(params.offer_id);
      var property = store.findRecord('property', propertyId);
      var order = store.findRecord('order', orderId);
      var offer = store.findRecord('offer', offerId);
      return Ember.RSVP.hash({
        property: property,
        order: order,
        offer: offer
      });
    }
  });

  _exports.default = _default;
});
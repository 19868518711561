define("hospodari/templates/components/transitioning-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4gqXCZs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isTransitioning\",\"start\"],[[24,[\"isTransitioning\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isTransitioning\"]]],null],true],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/transitioning-state.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/pagination-pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Properties:
    current: null,
    count: null,
    onChange: function onChange() {},
    prev: Ember.computed('current', function () {
      return Math.max(parseInt(Ember.get(this, 'current')) - 1, 1);
    }),
    next: Ember.computed('current', 'count', function () {
      return parseInt(Ember.get(this, 'current')) + 1;
    }),
    last: Ember.computed('count', function () {
      return parseInt(Ember.get(this, 'count'));
    }),
    // Flags
    showPagination: Ember.computed('count', function () {
      return parseInt(Ember.get(this, 'count')) > 1;
    }),
    isFirstDisabled: Ember.computed('current', function () {
      return parseInt(Ember.get(this, 'current')) === 1;
    }),
    isPreviousDisabled: Ember.computed('current', function () {
      return parseInt(Ember.get(this, 'current')) === 1;
    }),
    isNextDisabled: Ember.computed('current', 'count', function () {
      return parseInt(Ember.get(this, 'current')) >= parseInt(Ember.get(this, 'count'));
    }),
    isLastDisabled: Ember.computed('current', function () {
      return parseInt(Ember.get(this, 'current')) >= parseInt(Ember.get(this, 'count'));
    }),
    // Actions
    actions: {
      setPage: function setPage(page) {
        Ember.set(this, 'current', page);
        this.onChange(page);
      }
    }
  });

  _exports.default = _default;
});
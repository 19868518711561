define("hospodari/templates/components/business/business-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3MEBkHiX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card business-orders p-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"business-aside col-12 col-md-3\"],[8],[0,\"\\n      \"],[1,[28,\"business/business-navigation\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-9\"],[8],[0,\"\\n      \"],[1,[28,\"business/business-activation-message\",null,[[\"item\"],[[24,[\"item\"]]]]],false],[0,\"\\n\\n      \"],[1,[28,\"business/business-order-list\",null,[[\"item\",\"customer\",\"project\",\"product\",\"city\",\"service\",\"selectedService\",\"orders\",\"listType\",\"showObject\"],[[24,[\"item\"]],[24,[\"customer\"]],[24,[\"project\"]],[24,[\"product\"]],[24,[\"city\"]],[24,[\"service\"]],[24,[\"selectedService\"]],[24,[\"orders\"]],\"table\",false]]],false],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/business/business-orders.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/components/input-city", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W6/D0fI5",
    "block": "{\"symbols\":[\"prediction\"],\"statements\":[[4,\"power-select\",null,[[\"class\",\"triggerClass\",\"dropdownClass\",\"tagName\",\"searchEnabled\",\"options\",\"onChange\",\"selected\",\"search\",\"allowClear\",\"matchTriggerWidth\",\"placeholder\",\"searchPlaceholder\",\"searchMessage\",\"loadingMessage\",\"noMatchesMessage\",\"dropdownClass\"],[[24,[\"inputClass\"]],[24,[\"triggerClass\"]],[24,[\"dropdownClass\"]],[24,[\"tagName\"]],true,[24,[\"predictions\"]],[28,\"action\",[[23,0,[]],\"selectCity\"],null],[24,[\"selectedPlace\"]],[28,\"action\",[[23,0,[]],\"handleSearch\"],null],[24,[\"allowClear\"]],false,[24,[\"placeholder\"]],\"Або введіть назву іншого...\",\"Виберіть або введіть своє\",\"Завантажуємо міста...\",\"Такого міста немає\",\"input-city-dropdown\"]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"description\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"small\",true],[10,\"class\",\"text-muted\"],[8],[0,\"\\n      \"],[1,[23,1,[\"description\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"showDetails\"]]],null,{\"statements\":[[0,\"  \"],[7,\"small\",true],[10,\"class\",\"form-text text-black-50 px-2\"],[8],[0,\"\\n    \"],[1,[22,\"details\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/input-city.hbs"
    }
  });

  _exports.default = _default;
});
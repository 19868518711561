define("hospodari/templates/components/order/order-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x2fmyqhk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"title\",[29,[[22,\"status\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"draft\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-circle text-success\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" нове\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"opened\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-circle text-warning\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" надходять пропозиції\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"accepted\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" виконується\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"canceled\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash text-muted\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" скасовано\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"status\"]],\"finished\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-check text-success\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" завершено\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"status\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/components/order/order-status.hbs"
    }
  });

  _exports.default = _default;
});
define("hospodari/components/user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      save: function save(user) {
        var _this = this;

        return user.validate().then(function () {
          if (user.get("isValid")) {
            var _user = Ember.get(_this, "user"); // let validate = user.validate({except: ['password']});
            // if (validate) {


            return _user.save().then(function () {
              console.log("saved");
            }); // } else {
            //     set(this, 'user.isValidated', true);
            // }
          }
        });
      }
    }
  });

  _exports.default = _default;
});
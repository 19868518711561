define("hospodari/routes/my/buildings/orders", ["exports", "hospodari/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Угоди',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      per_page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var propertyId = parseInt(params.id);
      return Ember.RSVP.hash({
        item: store.findRecord('property', propertyId),
        orders: store.query('order', {
          'filter[property]': propertyId,
          page: params.page,
          per_page: params.per_page
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hospodari/templates/business/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "il8D3/f/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"model\",\"item\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"error/error-404\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"common/common-header\",null,[[\"search\",\"yield\"],[false,true]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"h1 mt-5\"],[8],[1,[24,[\"model\",\"item\",\"title\"]],false],[9],[0,\"\\n    \"],[7,\"nav\",true],[10,\"aria-label\",\"breadcrumb\"],[8],[0,\"\\n      \"],[7,\"ol\",true],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item\"],[8],[0,\"\\n          \"],[4,\"link-to\",null,[[\"route\"],[\"index\"]],{\"statements\":[[0,\"Головна\"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item\"],[8],[0,\"\\n          \"],[4,\"link-to\",null,[[\"route\"],[\"index\"]],{\"statements\":[[0,\"Бізнеси\"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"breadcrumb-item active\"],[8],[0,\"\\n          \"],[1,[24,[\"model\",\"item\",\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row mt-4 mb-4\"],[8],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-12\"],[8],[0,\"\\n\\n          \"],[1,[28,\"business/business-item-public\",null,[[\"item\",\"posts\",\"services\",\"reviews\"],[[24,[\"model\",\"item\"]],[24,[\"model\",\"posts\"]],[24,[\"model\",\"services\"]],[24,[\"model\",\"reviews\"]]]]],false],[0,\"\\n\\n        \"],[9],[0,\"\\n\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"common/common-footer\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hospodari/templates/business/item.hbs"
    }
  });

  _exports.default = _default;
});
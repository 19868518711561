define("hospodari/routes/jobs/index", ["exports", "hospodari/config/environment", "hospodari/utils/get-filters-from-params"], function (_exports, _environment, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      }
    },
    // Title
    titleToken: 'Вакансії',
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      params['site'] = _environment.default.api.site;
      params['per_page'] = 12;
      params['display'] = 'public';
      params['featured'] = true;
      params['lira'] = 158187;
      var filters = (0, _getFiltersFromParams.default)(params);
      return Ember.RSVP.hash({
        items: store.query('post', filters)
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
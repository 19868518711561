define("hospodari/routes/page", ["exports", "hospodari/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var siteId = _environment.default.api.site;
      var path = "/".concat(params.uid, "/");
      return Ember.get(this, 'store').query('page', {
        'filter[site]': siteId,
        'filter[path]': path,
        per_page: 1,
        page: 1
      }).then(function (pages) {
        var page = Ember.get(pages, 'firstObject');
        if (!page) return null;
        return store.findRecord('page', page.id);
      });
    },
    // After Model
    afterModel: function afterModel(model) {
      if (model) {
        var title = Ember.get(model, 'title');
        Ember.set(this, 'titleToken', title);
      } else {
        Ember.set(this, 'titleToken', '404 - Сторінку не знайдено');
      }
    }
  });

  _exports.default = _default;
});